var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      attrs: {
        model: _vm.distributeStoreForm,
        "label-width": "96px",
        rules: _vm.rules,
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "wrap",
          attrs: { id: "wrap" },
        },
        [
          _c("topOperatingButton", {
            attrs: {
              isImportOrderBtn: _vm.showImportBill,
              isAuditBillBtn: false,
              isAddBtn: _vm.showAdd,
              disabled: _vm.disabled,
              id: "topOperatingButton",
            },
            on: {
              submitForm: _vm.submitForm,
              addBill: _vm.addBill,
              handleAdd: _vm.handleAdd,
              getQuit: _vm.getQuit,
              getImportOrder: _vm.getImportOrder,
            },
            scopedSlots: _vm._u([
              {
                key: "specialDiyBtn",
                fn: function () {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "success",
                          disabled: _vm.showAudit,
                          size: "mini",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.auditBill("分货")
                          },
                        },
                      },
                      [_vm._v("分货 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          disabled: !_vm.distributeStoreForm.billId,
                          size: "mini",
                          type: "primary",
                        },
                        on: { click: _vm.handleExport },
                      },
                      [_vm._v("导出 ")]
                    ),
                    _c("NowPrintButton", {
                      staticStyle: { margin: "0 10px" },
                      attrs: {
                        dialogType: "PrintTemplateNowPrint",
                        billId: _vm.distributeStoreForm.billId,
                        filters: _vm.nowPrintOptions,
                      },
                    }),
                    _c("NowPrintButton", {
                      staticStyle: { "margin-right": "10px" },
                      attrs: {
                        dialogType: "PrintTemplateNowPrintShop",
                        billId: _vm.distributeStoreForm.billId,
                        filters: _vm.nowPrintOptions,
                        btnText: "分店打印",
                      },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _c(
            "cardTitleCom",
            {
              attrs: {
                cardTitle: "基本信息",
                id: "basicInformation",
                isCargoOrder: true,
                billStatus: _vm.distributeStoreForm.billStatus,
              },
            },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "x-w marT10" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "单据编号", prop: "billNo" } },
                      [
                        _c("el-input", {
                          staticClass: "inputWidth",
                          attrs: {
                            size: "mini",
                            disabled: true,
                            placeholder: "单据编号",
                          },
                          model: {
                            value: _vm.distributeStoreForm.billNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.distributeStoreForm, "billNo", $$v)
                            },
                            expression: "distributeStoreForm.billNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "单据日期", prop: "billDate" } },
                      [
                        _c("el-date-picker", {
                          staticStyle: { width: "170px" },
                          attrs: {
                            size: "mini",
                            type: "date",
                            placeholder: "单据日期",
                            disabled: _vm.distributeStoreForm.billStatus == "2",
                            "value-format": "yyyy-MM-dd",
                          },
                          model: {
                            value: _vm.distributeStoreForm.billDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.distributeStoreForm, "billDate", $$v)
                            },
                            expression: "distributeStoreForm.billDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "手工单据号", prop: "handBillNo" } },
                      [
                        _c("el-input", {
                          staticClass: "inputWidth",
                          attrs: {
                            size: "mini",
                            disabled: _vm.distributeStoreForm.billStatus == "2",
                            placeholder: "手工单据号",
                          },
                          model: {
                            value: _vm.distributeStoreForm.handBillNo,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.distributeStoreForm,
                                "handBillNo",
                                $$v
                              )
                            },
                            expression: "distributeStoreForm.handBillNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "生产工厂", prop: "produceDeptId" } },
                      [
                        _c("SelectRemote", {
                          staticStyle: { width: "250px" },
                          attrs: {
                            option: _vm.$select({
                              key: "listProduceDept",
                              option: {
                                option: {
                                  disabled:
                                    _vm.disabled ||
                                    _vm.isImportSource ||
                                    _vm.distributeStoreForm.billStatus == "2",
                                },
                              },
                            }).option,
                          },
                          on: { selectChange: _vm.changeProduceGroupChange },
                          model: {
                            value: _vm.distributeStoreForm.produceDeptId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.distributeStoreForm,
                                "produceDeptId",
                                $$v
                              )
                            },
                            expression: "distributeStoreForm.produceDeptId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "备注", prop: "billRemark" } },
                      [
                        _c("el-input", {
                          staticClass: "inputRemark",
                          attrs: {
                            size: "mini",
                            disabled: _vm.distributeStoreForm.billStatus == "2",
                            type: "textarea",
                            placeholder: "备注长度介于 1 和 80 字符之间",
                            maxlength: "40",
                            autosize: { minRows: 1, maxRows: 5 },
                            "show-word-limit": "",
                          },
                          model: {
                            value: _vm.distributeStoreForm.billRemark,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.distributeStoreForm,
                                "billRemark",
                                $$v
                              )
                            },
                            expression: "distributeStoreForm.billRemark",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            { staticClass: "headGoodsTable", attrs: { cardTitle: "商品信息" } },
            [
              _c("template", { slot: "rightCardTitle" }, [
                _c(
                  "div",
                  { staticClass: "marR10 x-f" },
                  [
                    _c("span", { staticClass: "fS14MR10" }, [
                      _vm._v("分货路线"),
                    ]),
                    _c("SelectLocal", {
                      staticStyle: { width: "250px" },
                      attrs: {
                        option: {
                          clearable: true,
                          data: _vm.deliverLineList,
                          value: "lineId",
                          label: "lineName",
                        },
                      },
                      on: { selectChange: _vm.deliverLineChange },
                      model: {
                        value: _vm.distributeStoreForm.deliverLineId,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.distributeStoreForm,
                            "deliverLineId",
                            $$v
                          )
                        },
                        expression: "distributeStoreForm.deliverLineId",
                      },
                    }),
                    _c(
                      "el-button",
                      {
                        staticClass: "marL10",
                        attrs: {
                          disabled: !_vm.distributeStoreForm.produceDeptId,
                          type: "primary",
                          size: "mini",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.getDistribution(1)
                          },
                        },
                      },
                      [_vm._v("批量修改仓库")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          disabled: _vm.disabled,
                          type: "primary",
                          size: "mini",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.getDistribution(2)
                          },
                        },
                      },
                      [_vm._v("分货对象")]
                    ),
                  ],
                  1
                ),
              ]),
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "goodsMessage" },
                  [
                    _c(
                      "vxe-table",
                      {
                        ref: "multipleTable",
                        attrs: {
                          border: "",
                          "show-overflow": "",
                          "show-header-overflow": "",
                          data: _vm.distributeStoreForm.detailItem,
                          loading: _vm.loadingTable,
                          "max-height": "600px",
                          height: "600px",
                          "checkbox-config": { trigger: "row" },
                          "row-config": { isHover: true, height: 60 },
                          "sort-config": { trigger: "cell" },
                          "scroll-y": { enabled: true },
                          "column-config": { resizable: true },
                        },
                        on: {
                          "checkbox-change": _vm.handleSelectionChange,
                          "checkbox-all": _vm.handleSelectionChange,
                        },
                      },
                      [
                        _c("vxe-column", {
                          attrs: {
                            fixed: "left",
                            type: "checkbox",
                            width: "60",
                            selectable: _vm.disableCheckbox,
                            align: "center",
                          },
                        }),
                        _c("vxe-column", {
                          attrs: {
                            align: "center",
                            title: _vm.tableCellLabel,
                            width: "80",
                            fixed: "left",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      on: {
                                        mouseenter: function ($event) {
                                          return _vm.cellMouseEnter(scope.row)
                                        },
                                        mouseleave: function ($event) {
                                          return _vm.cellMouseLeave(scope.row)
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: scope.row.hoverRow,
                                            expression: "scope.row.hoverRow",
                                          },
                                        ],
                                        staticClass:
                                          "el-icon-circle-plus operatePush",
                                        attrs: { disabled: _vm.disabled },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.row(
                                              "push",
                                              scope.rowIndex,
                                              scope.row
                                            )
                                          },
                                        },
                                      }),
                                      _c("i", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: scope.row.hoverRow,
                                            expression: "scope.row.hoverRow",
                                          },
                                        ],
                                        staticClass:
                                          "el-icon-remove operateDel",
                                        attrs: { disabled: _vm.disabled },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.row(
                                              "del",
                                              scope.rowIndex,
                                              scope.row
                                            )
                                          },
                                        },
                                      }),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: !scope.row.hoverRow,
                                              expression: "!scope.row.hoverRow",
                                            },
                                          ],
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(scope.rowIndex + 1) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c(
                          "vxe-column",
                          {
                            attrs: {
                              fixed: "left",
                              title: "商品编码",
                              width: "180",
                              align: "center",
                              field: "goodsNo",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop:
                                            "detailItem." +
                                            scope.rowIndex +
                                            ".goodsNo",
                                          rules:
                                            _vm.rules["detailItem.goodsNo"],
                                        },
                                      },
                                      [
                                        _c("SelectRemote", {
                                          ref:
                                            "goodsNo" +
                                            "_" +
                                            scope.rowIndex +
                                            "_1",
                                          attrs: {
                                            disabled: scope.row.isDelete == 0,
                                            option: _vm.optionGoods(scope.row),
                                          },
                                          on: {
                                            handleSelectKeydown: function (
                                              $event
                                            ) {
                                              return _vm.handleTableKeydown(
                                                $event,
                                                scope.row,
                                                "goodsNo",
                                                scope.rowIndex,
                                                1
                                              )
                                            },
                                          },
                                          model: {
                                            value: scope.row.goodsNo,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "goodsNo",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.goodsNo",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          },
                          [
                            _c("template", { slot: "header" }, [
                              _c("i", { staticStyle: { color: "#ff4949" } }, [
                                _vm._v("* "),
                              ]),
                              _c("span", [_vm._v("商品编码")]),
                            ]),
                          ],
                          2
                        ),
                        _c("vxe-column", {
                          attrs: {
                            fixed: "left",
                            title: "商品名称",
                            align: "center",
                            field: "goodsName",
                            width: "170",
                          },
                        }),
                        _c("vxe-column", {
                          attrs: {
                            title: "商品条码",
                            align: "center",
                            field: "barcode",
                            width: "170",
                          },
                        }),
                        _c("vxe-column", {
                          attrs: {
                            title: "规格",
                            align: "center",
                            field: "goodsSpec",
                            width: "150",
                          },
                        }),
                        _c(
                          "vxe-column",
                          {
                            attrs: {
                              title: "调出仓库",
                              align: "center",
                              field: "outStoreId",
                              "min-width": "180",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop:
                                            "detailItem." +
                                            scope.rowIndex +
                                            ".outStoreId",
                                          rules:
                                            _vm.rules["detailItem.outStoreId"],
                                        },
                                      },
                                      [
                                        _c("SelectRemote", {
                                          ref:
                                            "outStoreId" +
                                            "_" +
                                            scope.rowIndex +
                                            "_5",
                                          staticStyle: { width: "150px" },
                                          attrs: {
                                            disabled:
                                              scope.row.isDelete == 0 ||
                                              !_vm.distributeStoreForm
                                                .produceDeptId,
                                            option: _vm.$select({
                                              key: "listProduceStore",
                                              option: {
                                                option: {
                                                  remoteBody: {
                                                    produceDeptId:
                                                      _vm.distributeStoreForm
                                                        .produceDeptId,
                                                  },
                                                  disabled: _vm.disabled,
                                                  showItem: [
                                                    {
                                                      storeId:
                                                        scope.row.outStoreId,
                                                      produceStoreName:
                                                        scope.row.outStoreName,
                                                    },
                                                  ],
                                                },
                                              },
                                            }).option,
                                          },
                                          on: {
                                            selectChange: function (v, row) {
                                              return _vm.changeStore(
                                                scope.row,
                                                row
                                              )
                                            },
                                            handleSelectKeydown: function (
                                              $event
                                            ) {
                                              return _vm.handleTableKeydown(
                                                $event,
                                                scope.row,
                                                "outStoreId",
                                                scope.rowIndex,
                                                5
                                              )
                                            },
                                          },
                                          model: {
                                            value: scope.row.outStoreId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "outStoreId",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.outStoreId",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          },
                          [
                            _c("template", { slot: "header" }, [
                              _c("i", { staticStyle: { color: "#ff4949" } }, [
                                _vm._v("* "),
                              ]),
                              _c("span", [_vm._v("调出仓库")]),
                            ]),
                          ],
                          2
                        ),
                        _c("vxe-column", {
                          attrs: {
                            title: "单位",
                            align: "center",
                            field: "unitName",
                            width: "100",
                          },
                        }),
                        _vm._l(
                          _vm.distributeStoreForm.detailItem[0].storeItemCache,
                          function (item, index) {
                            return _c(
                              "vxe-colgroup",
                              {
                                key: item.uid + "." + index,
                                attrs: {
                                  title: item.orderStoreName,
                                  align: "center",
                                },
                              },
                              [
                                _c("vxe-column", {
                                  attrs: {
                                    title: "订货数",
                                    align: "center",
                                    field: "orderQty." + item.orderStoreId,
                                    width: "80",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  scope.row.storeItemCache[
                                                    index
                                                  ]
                                                    ? scope.row.storeItemCache[
                                                        index
                                                      ].orderQty
                                                    : ""
                                                )
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _c("vxe-column", {
                                  attrs: {
                                    title: "已分货数",
                                    align: "center",
                                    field:
                                      "alreadyDistributeQty." +
                                      item.orderStoreId,
                                    width: "80",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  scope.row.storeItemCache[
                                                    index
                                                  ]
                                                    ? scope.row.storeItemCache[
                                                        index
                                                      ].alreadyDistributeQty
                                                    : ""
                                                )
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _c("vxe-column", {
                                  attrs: {
                                    title: "分货数",
                                    align: "center",
                                    width: "100",
                                    field: "distributeQty." + item.orderStoreId,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  prop:
                                                    "detailItem." +
                                                    scope.rowIndex +
                                                    ".storeItemCache." +
                                                    index +
                                                    ".distributeQty",
                                                  rules:
                                                    _vm.rules[
                                                      "storeItem.storeItemCache"
                                                    ],
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  ref:
                                                    "distributeQty" +
                                                    "_" +
                                                    scope.rowIndex +
                                                    "_" +
                                                    _vm.dividedNumberTable[
                                                      index
                                                    ].columnindex,
                                                  refInFor: true,
                                                  attrs: {
                                                    size: "mini",
                                                    type: "number",
                                                    maxlength: "7",
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      return _vm.limitInputlength(
                                                        scope.row
                                                          .storeItemCache[index]
                                                          .distributeQty,
                                                        scope.row
                                                          .storeItemCache[
                                                          index
                                                        ],
                                                        "distributeQty",
                                                        true
                                                      )
                                                    },
                                                  },
                                                  nativeOn: {
                                                    mousewheel: function (
                                                      $event
                                                    ) {
                                                      $event.preventDefault()
                                                    },
                                                    DOMMouseScroll: function (
                                                      $event
                                                    ) {
                                                      $event.preventDefault()
                                                    },
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                    },
                                                    keyup: function ($event) {
                                                      return _vm.onkeyupDistributeQty(
                                                        scope.rowIndex
                                                      )
                                                    },
                                                    keydown: [
                                                      function ($event) {
                                                        if (
                                                          !$event.type.indexOf(
                                                            "key"
                                                          ) &&
                                                          _vm._k(
                                                            $event.keyCode,
                                                            "up",
                                                            38,
                                                            $event.key,
                                                            ["Up", "ArrowUp"]
                                                          )
                                                        ) {
                                                          return null
                                                        }
                                                        $event.preventDefault()
                                                        return _vm.handleTableKeydown(
                                                          $event,
                                                          scope.row,
                                                          "distributeQty",
                                                          scope.rowIndex,
                                                          _vm
                                                            .dividedNumberTable[
                                                            index
                                                          ].columnindex
                                                        )
                                                      },
                                                      function ($event) {
                                                        if (
                                                          !$event.type.indexOf(
                                                            "key"
                                                          ) &&
                                                          _vm._k(
                                                            $event.keyCode,
                                                            "down",
                                                            40,
                                                            $event.key,
                                                            [
                                                              "Down",
                                                              "ArrowDown",
                                                            ]
                                                          )
                                                        ) {
                                                          return null
                                                        }
                                                        $event.preventDefault()
                                                        return _vm.handleTableKeydown(
                                                          $event,
                                                          scope.row,
                                                          "distributeQty",
                                                          scope.rowIndex,
                                                          _vm
                                                            .dividedNumberTable[
                                                            index
                                                          ].columnindex
                                                        )
                                                      },
                                                      function ($event) {
                                                        if (
                                                          !$event.type.indexOf(
                                                            "key"
                                                          ) &&
                                                          _vm._k(
                                                            $event.keyCode,
                                                            "left",
                                                            37,
                                                            $event.key,
                                                            [
                                                              "Left",
                                                              "ArrowLeft",
                                                            ]
                                                          )
                                                        ) {
                                                          return null
                                                        }
                                                        if (
                                                          "button" in $event &&
                                                          $event.button !== 0
                                                        ) {
                                                          return null
                                                        }
                                                        $event.preventDefault()
                                                        return _vm.handleTableKeydown(
                                                          $event,
                                                          scope.row,
                                                          "distributeQty",
                                                          scope.rowIndex,
                                                          _vm
                                                            .dividedNumberTable[
                                                            index
                                                          ].columnindex
                                                        )
                                                      },
                                                      function ($event) {
                                                        if (
                                                          !$event.type.indexOf(
                                                            "key"
                                                          ) &&
                                                          _vm._k(
                                                            $event.keyCode,
                                                            "right",
                                                            39,
                                                            $event.key,
                                                            [
                                                              "Right",
                                                              "ArrowRight",
                                                            ]
                                                          )
                                                        ) {
                                                          return null
                                                        }
                                                        if (
                                                          "button" in $event &&
                                                          $event.button !== 2
                                                        ) {
                                                          return null
                                                        }
                                                        $event.preventDefault()
                                                        return _vm.handleTableKeydown(
                                                          $event,
                                                          scope.row,
                                                          "distributeQty",
                                                          scope.rowIndex,
                                                          _vm
                                                            .dividedNumberTable[
                                                            index
                                                          ].columnindex
                                                        )
                                                      },
                                                      function ($event) {
                                                        if (
                                                          !$event.type.indexOf(
                                                            "key"
                                                          ) &&
                                                          _vm._k(
                                                            $event.keyCode,
                                                            "enter",
                                                            13,
                                                            $event.key,
                                                            "Enter"
                                                          )
                                                        ) {
                                                          return null
                                                        }
                                                        $event.preventDefault()
                                                        return _vm.handleTableKeydown(
                                                          $event,
                                                          scope.row,
                                                          "distributeQty",
                                                          scope.rowIndex,
                                                          _vm
                                                            .dividedNumberTable[
                                                            index
                                                          ].columnindex
                                                        )
                                                      },
                                                    ],
                                                  },
                                                  model: {
                                                    value:
                                                      scope.row.storeItemCache[
                                                        index
                                                      ].distributeQty,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row
                                                          .storeItemCache[
                                                          index
                                                        ],
                                                        "distributeQty",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.storeItemCache[index].distributeQty",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            )
                          }
                        ),
                        _c("vxe-column", {
                          attrs: {
                            title: "订货总数",
                            align: "center",
                            field: "orderTotalQty",
                            width: "80",
                          },
                        }),
                        _c("vxe-column", {
                          attrs: {
                            title: "完工总数",
                            align: "center",
                            field: "sourceQty",
                            width: "80",
                          },
                        }),
                        _c("vxe-column", {
                          attrs: {
                            title: "库存总数",
                            align: "center",
                            field: "storeQty",
                            width: "80",
                          },
                        }),
                        _c("vxe-column", {
                          attrs: {
                            title: "已分货总数",
                            align: "center",
                            field: "alreadyDistributeTotalQty",
                            width: "100",
                          },
                        }),
                        _c("vxe-column", {
                          attrs: {
                            title: "分货总数",
                            align: "center",
                            field: "distributeTotalQty",
                            width: "80",
                          },
                        }),
                        _c("vxe-column", {
                          attrs: {
                            title: "备注",
                            align: "center",
                            field: "remark",
                            "min-width": "130",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-input", {
                                    ref:
                                      "remark" +
                                      "_" +
                                      scope.rowIndex +
                                      "_" +
                                      _vm.canKeyTableColumnList[
                                        _vm.canKeyTableColumnList.length - 1
                                      ].columnindex,
                                    attrs: {
                                      size: "mini",
                                      disabled: scope.row.isDelete == 0,
                                      maxlength: "80",
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                      },
                                      keydown: function ($event) {
                                        return _vm.handleTableKeydown(
                                          $event,
                                          scope.row,
                                          "remark",
                                          scope.rowIndex,
                                          _vm.canKeyTableColumnList[
                                            _vm.canKeyTableColumnList.length - 1
                                          ].columnindex
                                        )
                                      },
                                    },
                                    model: {
                                      value: scope.row.remark,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "remark", $$v)
                                      },
                                      expression: "scope.row.remark",
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c("Dialog", {
        ref: "tablePage",
        attrs: { options: _vm.dialogOptions },
        on: {
          "update:options": function ($event) {
            _vm.dialogOptions = $event
          },
          handleEvent: _vm.handleEvent,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }