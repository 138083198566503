var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-form", { ref: "form", attrs: { "label-width": "68px" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticClass: "wrap",
        attrs: { id: "wrap" },
      },
      [
        _c("topOperatingButton", {
          attrs: {
            isSubmitAddBtn: false,
            isAuditBillBtn: false,
            id: "topOperatingButton",
          },
          on: { submitForm: _vm.submitForm, getQuit: _vm.getQuit },
        }),
        _c(
          "cardTitleCom",
          { attrs: { cardTitle: "查询", id: "search-card" } },
          [
            _c("template", { slot: "cardContent" }, [
              _c(
                "div",
                { staticClass: "navSearch" },
                [
                  _c(
                    "div",
                    { staticClass: "marB10" },
                    [
                      _c("span", { staticClass: "fS14MR10" }, [
                        _vm._v("单据类型"),
                      ]),
                      _c(
                        "el-checkbox",
                        {
                          staticClass: "checkOrder",
                          model: {
                            value: _vm.queryParams.isShopOrder,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "isShopOrder", $$v)
                            },
                            expression: "queryParams.isShopOrder",
                          },
                        },
                        [_vm._v("门店订货单")]
                      ),
                      _c(
                        "el-checkbox",
                        {
                          staticClass: "checkOrder",
                          model: {
                            value: _vm.queryParams.isSell,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "isSell", $$v)
                            },
                            expression: "queryParams.isSell",
                          },
                        },
                        [_vm._v("销售订单")]
                      ),
                      _c(
                        "el-checkbox",
                        {
                          staticClass: "checkOrder",
                          model: {
                            value: _vm.queryParams.isProduce,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "isProduce", $$v)
                            },
                            expression: "queryParams.isProduce",
                          },
                        },
                        [_vm._v("生产计划单 ")]
                      ),
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.queryParams.isProduceOtherOrder,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.queryParams,
                                "isProduceOtherOrder",
                                $$v
                              )
                            },
                            expression: "queryParams.isProduceOtherOrder",
                          },
                        },
                        [_vm._v("其他订货单 ")]
                      ),
                    ],
                    1
                  ),
                  _c("seniorSearch", {
                    class: { marB10: !_vm.showHigh },
                    attrs: {
                      filterTimeStr: "thisMonth",
                      getList: _vm.getList,
                      isInvokeList: false,
                      refreshSearch: _vm.refreshQueryParams,
                    },
                    on: { isShowHighCom: _vm.getIsShowHigh },
                    model: {
                      value: _vm.queryParams,
                      callback: function ($$v) {
                        _vm.queryParams = $$v
                      },
                      expression: "queryParams",
                    },
                  }),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showHigh,
                          expression: "showHigh",
                        },
                      ],
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "x-w marT10" },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "marR10",
                              attrs: {
                                label: "到货日期",
                                prop: "datetimeArrival",
                              },
                            },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  size: "mini",
                                  type: "datetimerange",
                                  "start-placeholder": "开始日期",
                                  "end-placeholder": "结束日期",
                                  "default-time": ["00:00:00", "23:59:59"],
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                },
                                model: {
                                  value: _vm.datetimeArrival,
                                  callback: function ($$v) {
                                    _vm.datetimeArrival = $$v
                                  },
                                  expression: "datetimeArrival",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticClass: "marR10",
                              attrs: {
                                label: "生产班次",
                                prop: "produceOrderIds",
                              },
                            },
                            [
                              _c("SelectRemote", {
                                staticStyle: { width: "250px" },
                                attrs: {
                                  multiple: true,
                                  option: _vm.$select({
                                    key: "listProduceOrder",
                                    option: {
                                      option: {
                                        remoteBody: {
                                          produceDeptId:
                                            _vm.queryParams.produceDeptId,
                                        },
                                        multiple: true,
                                        remoteEcho: "produceOrderIds",
                                      },
                                    },
                                  }).option,
                                },
                                model: {
                                  value: _vm.queryParams.produceOrderIds,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.queryParams,
                                      "produceOrderIds",
                                      $$v
                                    )
                                  },
                                  expression: "queryParams.produceOrderIds",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticClass: "marR10",
                              attrs: {
                                label: "订货仓库",
                                prop: "orderingWarehouseIds",
                              },
                            },
                            [
                              _c("SelectRemote", {
                                staticStyle: { width: "250px" },
                                attrs: {
                                  multiple: true,
                                  option: _vm.$select({ key: "listStore" })
                                    .option,
                                },
                                model: {
                                  value: _vm.queryParams.orderingWarehouseIds,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.queryParams,
                                      "orderingWarehouseIds",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "queryParams.orderingWarehouseIds",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "订货客户",
                                prop: "orderingCustomerIds",
                              },
                            },
                            [
                              _c("SelectRemote", {
                                staticStyle: { width: "250px" },
                                attrs: {
                                  multiple: true,
                                  option: _vm.$select({ key: "listCustomer" })
                                    .option,
                                },
                                model: {
                                  value: _vm.queryParams.orderingCustomerIds,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.queryParams,
                                      "orderingCustomerIds",
                                      $$v
                                    )
                                  },
                                  expression: "queryParams.orderingCustomerIds",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ],
          2
        ),
        _c(
          "cardTitleCom",
          { staticClass: "orderInfo", attrs: { cardTitle: "选择源单" } },
          [
            _c("template", { slot: "cardContent" }, [
              _c(
                "div",
                { staticClass: "tableContent" },
                [
                  _c("div", { staticClass: "x-bc" }, [
                    _c(
                      "div",
                      { staticStyle: { width: "68%" } },
                      [
                        _c("div", { staticClass: "fontS14 fontBold marB5" }, [
                          _vm._v("源单信息"),
                        ]),
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loadingTable,
                                expression: "loadingTable",
                              },
                            ],
                            ref: "multipleTable",
                            staticStyle: { width: "100%" },
                            attrs: {
                              "tooltip-effect": "dark",
                              data: _vm.sourceList,
                              border: "",
                              "max-height": _vm.tableHeight,
                              height: _vm.tableHeight,
                            },
                            on: {
                              "row-click": _vm.handleRowClick,
                              "selection-change": _vm.handleSelectionChange,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                type: "selection",
                                width: "50",
                                align: "center",
                                fixed: "left",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "序号",
                                align: "center",
                                prop: "userId",
                                width: "60",
                                type: "index",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                "show-overflow-tooltip": "",
                                label: "单据编号",
                                align: "center",
                                prop: "billNo",
                                "min-width": "170",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "单据日期",
                                align: "center",
                                prop: "billDate",
                                "min-width": "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "源单类型",
                                align: "center",
                                prop: "importTypeName",
                                "min-width": "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "单据金额",
                                align: "center",
                                prop: "billMoney",
                                "min-width": "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "到货日期",
                                align: "center",
                                prop: "arrivalDate",
                                "min-width": "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "订单需求量",
                                align: "center",
                                prop: "billQty",
                                "min-width": "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "订货仓库",
                                align: "center",
                                prop: "inStoreName",
                                "min-width": "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "订货客户",
                                align: "center",
                                prop: "customerName",
                                "min-width": "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "单据摘要",
                                align: "center",
                                prop: "billSummary",
                                "min-width": "155",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "备注",
                                align: "center",
                                prop: "billRemark",
                                "min-width": "160",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticStyle: { width: "30%" } },
                      [
                        _c("div", { staticClass: "fontS14 fontBold marB5" }, [
                          _vm._v("未订货门店"),
                        ]),
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loadingTable,
                                expression: "loadingTable",
                              },
                            ],
                            ref: "multipleTable1",
                            staticStyle: { width: "100%" },
                            attrs: {
                              "tooltip-effect": "dark",
                              data: _vm.sourceShopList,
                              border: "",
                              "max-height": _vm.tableHeight,
                              height: _vm.tableHeight,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                label: "序号",
                                align: "center",
                                prop: "userId",
                                width: "80",
                                type: "index",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "分店",
                                align: "center",
                                prop: "shopNoName",
                                "min-width": "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "负责人",
                                align: "center",
                                prop: "linkEmployeeName",
                                "min-width": "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "电话",
                                align: "center",
                                prop: "linkEmployeeTel",
                                "min-width": "120",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("bottomPage", {
                    attrs: {
                      getList: _vm.getList,
                      idsCom: _vm.ids,
                      totalCom: _vm.total,
                      isCheck: _vm.isCheck,
                    },
                    on: { selectAllCom: _vm.selectAll },
                    model: {
                      value: _vm.queryParams,
                      callback: function ($$v) {
                        _vm.queryParams = $$v
                      },
                      expression: "queryParams",
                    },
                  }),
                ],
                1
              ),
            ]),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }