<template>
  <el-form ref="form" :model="planForm" label-width="96px" :rules="rules">
    <div class="wrap" id="wrap" v-loading="loading">
      <topOperatingButton :isImportOrderBtn="showImportBill" :disabled="disabled" :showAudit="showAudit"
        :isAddBtn="showAdd" @submitForm="submitForm" @addBill="addBill" @auditBill="auditBill" @handleAdd="handleAdd"
        @getQuit="getQuit" @getImportOrder="getImportOrder" id="topOperatingButton" />
      <!-- 基本信息盒子 -->
      <!-- 表格搜索条件 -->
      <cardTitleCom cardTitle="基本信息" :billStatus="planForm.billStatus" id="basicInformation">
        <template slot="cardContent">
          <!-- 商品信息 -->
          <div class="x-w marT10">
            <!-- 第一行 -->
            <el-form-item label="单据编号" prop="billNo">
              <el-input class="inputWidth" :disabled="true" v-model="planForm.billNo" placeholder="单据编号"
                size="mini"></el-input>
            </el-form-item>
            <el-form-item label="单据日期" prop="billDate">
              <el-date-picker style="width: 170px" v-model="planForm.billDate" type="date" placeholder="单据日期"
                :disabled="disabled" value-format="yyyy-MM-dd" size="mini">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="生产日期" prop="produceDate">
              <el-date-picker style="width: 170px" v-model="planForm.produceDate" type="date" placeholder="生产日期"
                :disabled="disabled" value-format="yyyy-MM-dd" size="mini">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="手工单据号" prop="handBillNo">
              <el-input class="inputWidth" :disabled="disabled" v-model="planForm.handBillNo" placeholder="手工单据号"
                size="mini"></el-input>
            </el-form-item>

            <el-form-item label="生产工厂" prop="produceDeptId">
              <SelectRemote @selectChange="changeProduceGroupChange" v-model="planForm.produceDeptId"
                style="width: 250px" :option="
                  $select({
                    key: 'listProduceDept',
                    option: {
                      option: {
                        disabled: disabled || isImportSource,
                      },
                    },
                  }).option
                " />
            </el-form-item>
            <el-form-item label="生产组" prop="produceGroupId" v-if="isPlanGroup == 'Y'">
              <SelectRemote @selectChange="produceGroupChange" v-model="planForm.produceGroupId" style="width: 250px"
                :disabled="disabled || !planForm.produceDeptId" :option="
                  $select({
                    key: 'listProduceGroup',
                    option: {
                      option: {
                        remoteBody: {
                          produceDeptId: planForm.produceDeptId,
                        },
                      },
                    },
                  }).option
                " />
            </el-form-item>
            <el-form-item label="生产班次" prop="produceOrderId">
              <SelectRemote v-model="planForm.produceOrderId" style="width: 250px"
                :disabled="disabled || !planForm.produceDeptId" :option="
                  $select({
                    key: 'listProduceOrder',
                    option: {
                      option: {
                        remoteBody: {
                          produceDeptId: planForm.produceDeptId,
                        },
                      },
                    },
                  }).option
                " />
            </el-form-item>
            <el-form-item label="备注" prop="billRemark">
              <el-input class="inputRemark" :disabled="disabled" v-model="planForm.billRemark" type="textarea"
                placeholder="备注长度介于 1 和 80 字符之间" maxlength="80" :autosize="{ minRows: 1, maxRows: 5 }" size="mini" />
            </el-form-item>
          </div>
        </template>
      </cardTitleCom>
      <!-- 表格商品信息卡片 -->
      <cardTitleCom cardTitle="商品信息" class="headGoodsTable">
        <template slot="cardContent">
          <!-- 商品信息按钮和表格整体盒子 -->
          <div class="goodsMessage">
            <vxe-table border show-overflow show-header-overflow ref="multipleTable" :data="planForm.detailItem"
              :loading="loadingTable" max-height="560px" height="560px" :checkbox-config="{ trigger: 'row' }"
              :row-config="{ isHover: true, height: 60 }" :sort-config="{ trigger: 'cell' }"
              :scroll-y="{ enabled: true }" show-footer :footer-method="footerMethod">
              <vxe-column align="center" :title="tableCellLabel" width="80" fixed="left">
                <template v-slot="scope">
                  <div @mouseenter="cellMouseEnter(scope.row)" @mouseleave="cellMouseLeave(scope.row)">
                    <i v-show="scope.row.hoverRow" :disabled="disabled" @click="row('push', scope.rowIndex)"
                      class="el-icon-circle-plus operatePush"></i>
                    <i v-show="scope.row.hoverRow" :disabled="disabled" @click="row('del', scope.rowIndex)"
                      class="el-icon-remove operateDel"></i>
                    <div v-show="!scope.row.hoverRow">
                      {{ scope.rowIndex + 1 }}
                    </div>
                  </div>
                </template>
              </vxe-column>
              <vxe-column title="商品编码" align="center" field="goodsNo">
                <template slot="header">
                  <i style="color: #ff4949">* </i>
                  <span>商品编码</span>
                </template>
                <template slot-scope="scope">
                  <el-form-item :prop="'detailItem.' + scope.rowIndex + '.goodsNo'"
                    :rules="rules[`detailItem.goodsNo`]">
                    <SelectRemote :disabled="disabled" v-model="scope.row.goodsNo" :option="optionGoods(scope.row)" />
                  </el-form-item>
                </template>
              </vxe-column>
              <vxe-column title="商品名称" align="center" field="goodsName" />
              <vxe-column title="规格" align="center" field="goodsSpec" width="120"><template slot="header">
                  <i style="color: #ff4949">* </i>
                  <span>规格</span>
                </template>
                <template slot-scope="scope">
                  <el-form-item :prop="'detailItem.' + scope.rowIndex + '.goodsSpec'"
                    :rules="rules[`detailItem.goodsSpec`]">
                    <el-select v-model="scope.row.goodsSpec" :disabled="disabled" size="mini" @change="
                        changeUnits('goodsSpec', scope.row, scope.row.goodsSpec)
                      ">
                      <el-option v-for="item in scope.row.units" :key="item.goodsSpec" :label="item.goodsSpec"
                        :value="item.goodsSpec" />
                    </el-select>
                  </el-form-item>
                </template>
              </vxe-column>
              <vxe-column title="单位" align="center" field="unitId" width="110">
                <template slot="header">
                  <i style="color: #ff4949">* </i>
                  <span>单位</span>
                </template>
                <template slot-scope="scope">
                  <el-form-item :prop="'detailItem.' + scope.rowIndex + '.unitId'" :rules="rules[`detailItem.unitId`]">
                    <el-select v-model="scope.row.unitId" :disabled="disabled" size="mini" @change="
                        changeUnits('unitId', scope.row, scope.row.unitId)
                      ">
                      <el-option v-for="item in scope.row.units" :key="item.unitId" :label="item.unitName"
                        :value="item.unitId" />
                    </el-select>
                  </el-form-item>
                </template>
              </vxe-column>
              <vxe-column title="订单需求量" align="center" field="needUnitQty" width="100" />
              <vxe-column title="批数" align="center" width="120" field="batchUnitQty">
                <template slot="header">
                  <i style="color: #ff4949">* </i>
                  <span>批数</span>
                </template>
                <template slot-scope="scope">
                  <el-form-item :prop="'detailItem.' + scope.rowIndex + '.batchUnitQty'"
                    :rules="rules[`detailItem.batchUnitQty`]">
                    <el-input @mousewheel.native.prevent @DOMMouseScroll.native.prevent type="number"
                      v-model="scope.row.batchUnitQty" @keyup.native="onkeyupBatchUnitQty(scope.row)"
                      :disabled="disabled" size="mini" maxlength="7" @input="changeBatchUnitQty(scope.row)"></el-input>
                  </el-form-item>
                </template>
              </vxe-column>
              <vxe-column title="生产计划数" align="center" width="130" field="unitQty">
                <template slot="header">
                  <i style="color: #ff4949">* </i>
                  <span>生产计划数</span>
                </template>
                <template slot-scope="scope">
                  <el-form-item :prop="'detailItem.' + scope.rowIndex + '.unitQty'"
                    :rules="rules[`detailItem.unitQty`]">
                    <el-input @mousewheel.native.prevent @DOMMouseScroll.native.prevent type="number"
                      @keyup.native="onkeyupUnitQty(scope.row)" v-model="scope.row.unitQty" :disabled="disabled"
                      size="mini" maxlength="7" :min="0" @input="
                        limitInputlength(
                          scope.row.unitQty,
                          scope.row,
                          'unitQty',
                          true
                        )
                      " :sunyunFormat="(v) => $syInput(1, v)"></el-input>
                  </el-form-item>
                </template>
              </vxe-column>
              <vxe-column title="半成品仓库存" align="center" field="halfProductStoreQty" />
              <vxe-column title="成品仓库存" align="center" field="productStoreQty" />
              <vxe-column title="备注" align="center" field="remark">
                <template slot-scope="scope">
                  <el-input maxlength="80" v-model="scope.row.remark" :disabled="disabled" size="mini"></el-input>
                </template>
              </vxe-column>
            </vxe-table>
          </div>
        </template>
      </cardTitleCom>
    </div>
  </el-form>
</template>

<script>
import { getNewDate } from "@/utils/newDate"; //单据日期
import cardTitleCom from "@/views/components/cardTitleCom"; //卡片标题
import { getBillNo } from "@/api/codeRule"; //单据号
import {
  planAddAPI,
  planDetailAPI,
  planUpdateAPI,
  planUpdateStatusAPI,
  planGetGoodsInfo,
} from "@/api/produce/bill/plan"; //生产计划单
import topOperatingButton from "@/views/components/topOperatingButton"; //顶部按钮
import { limitInputlength, fcount, isNumber } from "@/utils/index.js"; //保留几位小数
import {
  getTableSelect, //下拉框
  getSelectAssign, //赋值
} from "@/utils/orderTableSelect.js"; //表格的下拉框公共配置
import { produceListAPI } from "@/api/system/maintain/param/produceParam"; //业务参数的生产组控制
import { produceDeptList } from "@/api/produce/sets/produceDept"; //生产工厂
import SelectRemote from "@/components/tablePage/select/select-remote/index.vue";
import SelectLocal from "@/components/tablePage/select/select-local/index.vue";
import roureMinxin from "@/utils/roure-minxin";
export default {
  name: "PlanDetail",
  mixins: [roureMinxin],
  components: {
    cardTitleCom,
    topOperatingButton,
    SelectRemote,
    SelectLocal,
  },
  data() {
    return {
      isImportSource: false, //是否引入源单
      produceGroupIdOld: "",
      isEmptyGoods: false,
      isPlanGroup: "N",
      tableCellLabel: "序号",
      showImportBill: false, //引入源单按钮
      showAdd: false, //新增按钮
      showAudit: true, //审核按钮禁用状态
      //表单禁用
      disabled: false,
      //表格遮罩
      loadingTable: false,
      // 遮罩层
      loading: false,
      //旧的表单
      oldForm: {},
      // 表单参数
      planForm: {
        billDate: "", //单据日期
        produceDate: "", //生产日期
        billStatus: "", //单据审核状态
        //单据表格-订单明细
        detailItem: [{}],
      },
      // 表单校验
      rules: {
        billNo: [
          {
            required: true,
            message: "请到编码规则配置单据类型",
            trigger: ["blur", "change"],
          },
        ],
        billDate: [
          {
            required: true,
            message: "单据日期不能为空",
            trigger: ["blur", "change"],
          },
        ],
        produceDate: [
          {
            required: true,
            message: "生产日期不能为空",
            trigger: ["blur", "change"],
          },
        ],
        produceDeptId: [
          {
            required: true,
            message: "生产工厂不能为空",
            trigger: ["blur", "change"],
          },
        ],
        produceGroupId: [
          {
            required: true,
            message: "生产组不能为空",
            trigger: ["blur", "change"],
          },
        ],
        "detailItem.goodsNo": [
          {
            required: true,
            message: "商品编码不能为空",
            trigger: ["blur", "change"],
          },
        ],
        "detailItem.goodsSpec": [
          {
            required: true,
            message: "规格不能为空",
            trigger: ["blur", "change"],
          },
        ],
        "detailItem.unitId": [
          {
            required: true,
            message: "单位不能为空",
            trigger: ["blur", "change"],
          },
        ],
        "detailItem.batchUnitQty": [
          {
            required: true,
            message: "批产量数不能为空",
            trigger: ["blur", "change"],
          },
          {
            pattern: /^\d{0,8}(\.\d{0,4})?$/,
            message: "超出输入字符范围",
          },
        ],
        "detailItem.unitQty": [
          {
            required: true,
            message: "生产计划数不能为空",
            trigger: ["blur", "change"],
          },
          { validator: this.validateUnitQty, trigger: ["blur", "change"] },
        ],
      },
    };
  },
  watch: {
    //侦听是否开启关闭按钮
    "planForm.billStatus": {
      handler(newVal) {
        if (this.planForm.billStatus == "2") {
          //引入源单按钮
          this.showImportBill = false;
          //新增按钮
          this.showAdd = true;

          //禁用输入按钮、保存按钮
          this.disabled = true;
          //打开审核按钮
          this.showAudit = false;
        } else if (this.planForm.billStatus == "3") {
          //引入源单按钮
          this.showImportBill = false;
          //新增按钮
          this.showAdd = false;
          //禁用审核按钮
          this.showAudit = true;
          //禁止全部输入按钮
          this.disabled = true;
        } else if (this.planForm.billStatus == "0") {
          //引入源单按钮
          this.showImportBill = false;
          //新增按钮
          this.showAdd = false;

          //禁用审核按钮
          this.showAudit = false;

          //恢复全部输入
          this.disabled = false;
        } else if (this.planForm.billStatus == "4") {
          //引入源单按钮
          this.showImportBill = false;
          //新增按钮
          this.showAdd = false;

          //禁用审核按钮
          this.showAudit = false;

          //恢复全部输入
          this.disabled = false;
        } else if (this.planForm.billStatus == "") {
          //引入源单按钮
          this.showImportBill = true;
          //新增按钮
          this.showAdd = false;

          //禁用审核按钮
          this.showAudit = true;
          //恢复全部输入
          this.disabled = false;
        }
      },
      immediate: true,
    },
    "planForm.detailItem": {
      handler() {
        this.$nextTick(() => {
          this.$refs.multipleTable.updateFooter();
        });
      },
      deep: true,
    },
    //生产组是否必须填
    isPlanGroup: {
      handler(newVal) {
        if (this.isPlanGroup == "Y") {
          this.rules.produceGroupId[0].required = true;
        } else {
          this.rules.produceGroupId[0].required = false;
        }
      },
      immediate: true,
    },
  },
  computed: {
    //商品下拉框配置项
    optionGoods() {
      var that = this;
      return function (row) {
        return getTableSelect(
          {
            goodsTypes: [1, 2],
            billDetailItems: this.planForm.detailItem,
            getMultipleGoods: this.getGoodS,
            row,
          },
          that
        );
      };
    },
  },
  methods: {
    footerMethod({ columns, data }) {
      // 返回一个二维数组的表尾合计 updateFooter
      let ol = [
        columns.map((column, columnIndex) => {
          if (columnIndex === 0) {
            return "合计";
          }
          if (
            [
              "needUnitQty",
              "batchUnitQty",
              "unitQty",
              "halfProductStoreQty",
              "productStoreQty",
            ].includes(column.property) ||
            column.property?.indexOf?.(".") >= 0
          ) {
            return data
              .map((x) => x[column.property])
              .reduce(
                (p, c) => fcount([p, isNumber(Number(c)) ? Number(c) : 0], "+"),
                0
              );
          }
          return "";
        }),
      ];

      return ol;
    },
    changeUnits(name, row, val) {
      let obj = row.units.find((v) => v[name] == val);
      if (name == "goodsSpec") {
        this.$set(row, "unitId", obj.unitId);
      } else if (name == "unitId") {
        this.$set(row, "goodsSpec", obj.goodsSpec);
      }
    },
    //页面加载钩子函数
    async onPageShow() {
      //页面加载动画
      this.loading = true;
      // 访问组件实例 this 中的数据和方法
      if (this.$route.query.type === "update") {
        if (
          !this.planForm.billId ||
          this.planForm.billId != this.$route.query.billId
        ) {
          console.log("详情", this.planForm);
          //初始化数据
          await this.reset();
          const res = await produceListAPI();
          //生产计划单分生产组制作
          this.isPlanGroup = res.data?.system_BUSINESS_PRODUCE_CONFIG_12;
          //获取单据详情
          const { data } = await planDetailAPI(this.$route.query.billId);
          //单据赋值
          this.planForm = data;
        }
      } else if (this.$route.query.type === "add") {
        if (
          !this.planForm.timestampId ||
          this.planForm.timestampId != this.$route.query.timestampId
        ) {
          console.log("新增", this.planForm);
          //新增
          //初始化数据
          await this.reset();
          await this.getInitializeData();
          await this.getOrderBillNo();
          this.planForm.timestampId = this.$route.query.timestampId;
        }
      } else if (this.$route.query.type == "importPlan") {
        try {
          await this.getInitializeData(false);
          await this.getOrderBillNo();
          console.log("引入源单", this.planForm);
          //缓存
          this.planForm.produceDeptId = this.$route.query?.produceDeptId
            ? Number(this.$route.query?.produceDeptId)
            : "";
          this.planForm.produceGroupId = this.$route.query?.produceGroupId;
          this.planForm.produceOrderId = this.$route.query?.produceOrderId;
          //判断是否有以前新增时保存的数据
          this.isImportSource = true;
          //缓存工厂
          if (this.$route.query.sourceGoodsList) {
            this.$set(
              this.planForm,
              "detailItem",
              JSON.parse(this.$route.query.sourceGoodsList)
            );
            this.$set(
              this.planForm,
              "importDetailItem",
              JSON.parse(this.$route.query.queryList)
            );
            //标记引入源单的商品，用来切换生产组时清空
            if (
              this.isPlanGroup == "Y" &&
              this.planForm.detailItem.length > 0
            ) {
              this.isEmptyGoods = true;
              this.produceGroupIdOld = this.planForm.produceGroupId;
            } else {
              this.isEmptyGoods = false;
            }
            if (this.planForm.detailItem.length == 0) {
              this.planForm.detailItem = [{}];
            }
          }
        } catch (error) { }
      }
      this.loading = false;
    },
    validateUnitQty(rule, value, callback) {
      if (value === "0") {
        callback(new Error("生产计划数不能为0"));
      } else {
        callback();
      }
    },
    //表格行hover时,显示操作加减号
    cellMouseEnter(row) {
      if (
        this.planForm.billStatus == "0" ||
        this.planForm.billStatus == "" ||
        !this.planForm.billStatus
      ) {
        this.$set(row, "hoverRow", true);
        this.tableCellLabel = "操作";
      }
    },
    //表格行离开hover时,不显示操作加减号,显示序号
    cellMouseLeave(row) {
      // 移除hover的事件
      if (
        this.planForm.billStatus == "0" ||
        this.planForm.billStatus == "" ||
        !this.planForm.billStatus
      ) {
        this.$set(row, "hoverRow", false);
        this.tableCellLabel = "序号";
      }
    },
    limitInputlength,
    changeProduceGroupChange(v) {
      this.$set(this.planForm, "produceGroupId", undefined);
      this.$set(this.planForm, "produceOrderId", undefined);
    },
    //切换分组
    produceGroupChange() {
      if (this.produceGroupIdOld && this.isEmptyGoods) {
        this.$confirm("修改生产组，会清空数据, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.planForm.detailItem = [{}];
            this.isPlanGroup = false;
            this.produceGroupIdOld = undefined;
          })
          .catch(() => {
            this.planForm.produceGroupId = this.produceGroupIdOld;
          });
      }
    },
    //退出
    getQuit() {
      // 关闭当前tab页签，打开新页签
      this.$tab.closeOpenPage({
        path: "/produce/bill/plan",
      });
    },

    //生产计划数
    onkeyupUnitQty(row) {
      if (row.batchProdQty && row.unitQty) {
        let batchUnitQty = this.$syInput(
          1,
          (row.unitQty / row.batchProdQty).toFixed(1)
        );
        // batchUnitQty = this.formatNumber(batchUnitQty)
        this.$set(row, "batchUnitQty", batchUnitQty);
      }
    },
    //批数
    onkeyupBatchUnitQty(row) {
      if (row.batchProdQty && row.batchUnitQty) {
        let unitQty = row.batchProdQty * row.batchUnitQty;
        unitQty = this.$syInput(1, this.formatNumber(unitQty));
        this.$set(row, "unitQty", unitQty);
      }
    },
    //批数
    changeBatchUnitQty(row) {
      // 检查是否存在小数位，并且小数位是否为0
      if (row.batchUnitQty % 1 === 0) {
        // 如果没有小数位或者小数位为0，则移除小数点后的0
        row.batchUnitQty = Number(row.batchUnitQty).toFixed(0);
      } else {
        // 如果有小数位，则保留一位小数
        row.batchUnitQty = Number(row.batchUnitQty).toFixed(1);
      }
    },
    formattedBatchUnitQty(row) {
      // 格式化函数，用于v-model的双向绑定
      // 这里返回处理后的值，但注意：这不会触发input事件
      return parseFloat(row.batchUnitQty);
    },
    //保存并新增单据
    async addBill() {
      this.$confirm("是否保存并新增单据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
      })
        .then(async () => {
          try {
            this.loading = true;
            await this.submitForm(false);
          } finally {
            this.loading = false;
          }
        })
        .catch();
    },
    //新增按钮
    async handleAdd() {
      this.loading = true;
      await this.reset();
      await this.getOrderBillNo();
      await this.getInitializeData();
      this.loading = false;
    },
    //引入源单
    getImportOrder() {
      if (!this.planForm.produceDeptId)
        return this.$message.error("生产工厂不能为空");
      if (this.isPlanGroup == "Y" && !this.planForm.produceGroupId)
        return this.$message.error("请先选择生产组才能引入源单");

      // console.log('新增produceDeptId', this.planForm.produceDeptId)

      this.$router.push({
        name: "planImport",
        query: {
          produceGroupId: this.planForm?.produceGroupId,
          produceDeptId: this.planForm?.produceDeptId,
          produceOrderId: this.planForm?.produceOrderId,
          type: "planImport",
        },
      });
    },
    //选中的商品
    async getGoodS(rows) {
      this.loadingTable = true;
      //查询库存条件
      let goodsIds = rows.map((v) => v.goodsId);
      //查询库存
      let res = await planGetGoodsInfo({ goodsIds });
      let inventoryArr = res.data || [];
      rows = rows.map((item) => {
        item.remark = undefined;
        //序号
        item.hoverRow = false;
        //手动添加
        item.lineType = 0;
        const storeNum = inventoryArr.find((v) => v.goodsId == item.goodsId);
        //批产量
        item.batchProdQty = storeNum?.batchProdQty || 0;
        //半成品库存
        item.halfProductStoreQty = storeNum?.halfProductStoreQty || 0;
        //成品库存
        item.productStoreQty = storeNum?.productStoreQty || 0;
        return item;
      });
      this.planForm.detailItem = getSelectAssign(
        rows,
        this.planForm.detailItem
      );
      this.loadingTable = false;
    },
    //表格增加/减少一行方法
    row(name, index) {
      if (name === "push") {
        this.planForm.detailItem.splice(index + 1, 0, {
          goodsNo: "",
          hoverRow: false,
        });
      } else {
        if (this.planForm.detailItem.length <= 1) {
          this.planForm.detailItem = [{
            goodsNo: "",
            hoverRow: false,
          }];
        } else {
          this.planForm.detailItem.splice(index, 1);
        }
      }
    },

    //审核/删除/终止事件
    async auditBill(name) {
      try {
        await this.$confirm(`确定${name}该订单, 是否继续?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "success",
        });

        if (name === "审核") {
          if (this.planForm.billStatus != "0") {
            this.$message.error("此单据不是未审核的状态哦~");
            return;
          }
          await this.submitForm(true, "audit");
          const obj = {
            billIds: [this.planForm.billId],
            billStatus: "2",
          };
          //发送审核api
          const { data } = await planUpdateStatusAPI(obj);
          //单据赋值
          data.deptName = data.produceDeptName;
          this.planForm = data;
          // 弹出提示
          // this.$message.success('审核成功')
        } else if (name === "反审核") {
          if (this.planForm.billStatus != "2") {
            this.$message.error("此单据不是已审核的状态哦~");
            return;
          }
          const obj = {
            billIds: [this.planForm.billId],
            billStatus: "0",
          };
          //发送反审核api
          const { data } = await planUpdateStatusAPI(obj);
          //单据赋值
          data.deptName = data.produceDeptName;
          this.planForm = data;
          // 弹出提示
          this.$message.success("反审核成功");
        }
      } catch { }
    },

    //初始化页面数据
    async getInitializeData(isBool = true) {
      if (!this.loading) this.loading = true;
      //获取单据日期
      const time = getNewDate();
      this.planForm.billDate = time;
      this.planForm.produceDate = time;
      const res = await produceListAPI();
      //生产计划单分生产组制作
      this.isPlanGroup = res.data?.system_BUSINESS_PRODUCE_CONFIG_12;
      if (isBool) {
        //默认生产工厂
        const res2 = await produceDeptList({ produceDeptNo: "0000" });
        if (res2.rows[0]?.produceDeptId) {
          //默认生产工厂
          this.$set(
            this.planForm,
            "produceDeptId",
            res2.rows[0]?.produceDeptId
          );
          //默认生产工厂名称
          this.$set(
            this.planForm,
            "produceDeptName",
            res2.rows[0]?.produceDeptName
          );
          // this.changeProduceGroupChange(res2.rows[0]?.produceDeptId)
        }
      }

      this.loading = false;
    },
    //单据编号
    async getOrderBillNo() {
      let billNo = await getBillNo(170201);
      this.$set(this.planForm, "billNo", billNo);
    },
    // 表单重置
    reset() {
      this.isEmptyGoods = false;
      this.isImportSource = false; //是否引入源单
      //新增按钮
      this.showAdd = false;
      //保存禁用
      this.disabled = false;
      this.planForm = {
        billDate: "", //单据日期
        produceDate: "", //生产日期
        billStatus: "", //单据审核状态
        //单据表格-订单明细
        detailItem: [{}],
      };
      this.resetForm("form");
    },
    /** 提交按钮 */
    async submitForm(isBool, audit) {
      const valid = await this.$refs["form"].validate();
      try {
        this.loading = true;
        if (valid) {
          if (this.planForm.billId) {
            const { data } = await planUpdateAPI(this.planForm);
            //重新赋值给表单，可以把id赋值
            data.deptName = data.produceDeptName;
            if (isBool) {
              this.planForm = data;
              this.$modal.msgSuccess(audit ? "审核成功" : "修改单据成功");
            } else {
              await this.reset();
              await this.getOrderBillNo();
              //获取单据日期
              const time = getNewDate();
              this.planForm.billDate = time;
              this.planForm.produceDate = time;
              this.$modal.msgSuccess("保存并新增单据成功");
            }
          } else {
            const { data } = await planAddAPI(this.planForm);
            data.deptName = data.produceDeptName;
            //重新赋值给表单，可以把id赋值
            if (isBool) {
              this.planForm = data;
              this.$modal.msgSuccess(audit ? "审核成功" : "新增单据成功");
            } else {
              await this.reset();
              await this.getOrderBillNo();
              //获取单据日期
              const time = getNewDate();
              this.planForm.billDate = time;
              this.planForm.produceDate = time;
              this.$modal.msgSuccess("保存并修改单据成功");
            }
          }
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
//整体盒子
.wrap {
  padding: 40px 10px 0 10px;
  background-color: #eaeaea;
  // height: calc(100vh - 84px);
  .headGoodsTable {
    //商品表格卡片
    .goodsMessage {
      padding: 6px 10px;
      ::v-deep .el-form-item__content {
        margin-left: 0 !important;
      }
      ::v-deep .el-form-item__error {
        position: static;
      }
      ::v-deep .el-form-item {
        margin-bottom: 0;
      }
    }
  }
}
::v-deep .el-form-item {
  margin-bottom: 20px;
}
//输入框原生属性
/**
 * 解决el-input设置类型为number时，中文输入法光标上移问题
 **/
::v-deep .el-input__inner {
  line-height: 1px !important;
}

/**
 * 解决el-input设置类型为number时，去掉输入框后面上下箭头
 **/
::v-deep input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
::v-deep .el-input__suffix-inner {
  z-index: 11;
  height: 100%;
  display: inline-block;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
