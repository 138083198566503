<template>
  <el-form ref="form" :model="matchMaterialForm" label-width="96px" :rules="rules">
    <div class="wrap" v-loading="loading">
      <topOperatingButton :isImportOrderBtn="showImportBill" :disabled="disabled" :showAudit="showAudit"
        :isAddBtn="showAdd" @submitForm="submitForm" @addBill="addBill" @auditBill="auditBill" @handleAdd="handleAdd"
        @getQuit="getQuit" @getImportOrder="getImportOrder" />
      <!-- 基本信息盒子 -->
      <!-- 表格搜索条件 -->
      <cardTitleCom cardTitle="基本信息" :billStatus="matchMaterialForm.billStatus">
        <template slot="cardContent">
          <!-- 商品信息 -->
          <div class="x-w marT10">
            <!-- 第一行 -->
            <el-form-item label="单据编号" prop="billNo">
              <el-input :disabled="true" v-model="matchMaterialForm.billNo" placeholder="单据编号" size="mini"></el-input>
            </el-form-item>

            <el-form-item label="单据日期" prop="billDate">
              <el-date-picker v-model="matchMaterialForm.billDate" type="date" placeholder="单据日期" :disabled="disabled"
                value-format="yyyy-MM-dd" size="mini">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="手工单据号" prop="handBillNo">
              <el-input :disabled="disabled" v-model="matchMaterialForm.handBillNo" placeholder="手工单据号"
                size="mini"></el-input>
            </el-form-item>
            <el-form-item label="生产工厂" prop="produceDeptId">
              <SelectRemote @selectChange="changeProduceGroupChange" v-model="matchMaterialForm.produceDeptId" :option="
                  $select({
                    key: 'listProduceDept',
                    option: {
                      option: {
                        disabled: disabled || matchMaterialForm.billId!=undefined,
                      },
                    },
                  }).option
                " />
            </el-form-item>
            <el-form-item label="配料仓库" prop="matchStoreId">
              <SelectRemote v-model="matchMaterialForm.matchStoreId" :option="
                        $select({
                          key: 'listProduceStore',
                          option: {
                            option: {
                              remoteBody: {
                                produceDeptId: matchMaterialForm.produceDeptId,
                              },
                              disabled: disabled|| !matchMaterialForm.produceDeptId||matchMaterialForm.billId!=undefined,
                              showItem: [
                                {
                                  storeId:matchMaterialForm.matchStoreId,
                                  produceStoreName:matchMaterialForm.matchStoreName,
                                },
                              ],
                            },
                          },
                        }).option
                      " />
            </el-form-item>
            <el-form-item label="原料仓库" prop="materialStoreId">
              <SelectRemote v-model="matchMaterialForm.materialStoreId" :option="
                        $select({
                          key: 'listProduceStore',
                          option: {
                            option: {
                              remoteBody: {
                                produceDeptId: matchMaterialForm.produceDeptId,
                              },
                              disabled: disabled|| !matchMaterialForm.produceDeptId||matchMaterialForm.billId!=undefined,
                              showItem: [
                                {
                                  storeId:matchMaterialForm.materialStoreId,
                                  produceStoreName:matchMaterialForm.materialStoreName,
                                },
                              ],
                            },
                          },
                        }).option
                      " />
            </el-form-item>
            <el-form-item label="半成品仓库" prop="halfStoreId">
              <SelectRemote v-model="matchMaterialForm.halfStoreId" :option="
                        $select({
                          key: 'listProduceStore',
                          option: {
                            option: {
                              remoteBody: {
                                produceDeptId: matchMaterialForm.produceDeptId,
                              },
                              disabled: disabled||!matchMaterialForm.produceDeptId||matchMaterialForm.billId!=undefined,
                              showItem: [
                                {
                                  storeId:matchMaterialForm.halfStoreId,
                                  produceStoreName:matchMaterialForm.halfStoreName,
                                },
                              ],
                            },
                          },
                        }).option
                      " />
            </el-form-item>
            <el-form-item label="配料类型" prop="matchMaterialType">
              <el-radio-group v-model="matchMaterialForm.matchMaterialType"
                :disabled='matchMaterialForm.billId!=undefined'>
                <el-radio :label="1">商品分开配料</el-radio>
                <el-radio :label="2">商品汇总配料</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="备注" prop="billRemark">
              <el-input class="inputRemark" :disabled="disabled" v-model="matchMaterialForm.billRemark" type="textarea"
                placeholder="备注长度介于 1 和 80 字符之间" maxlength="80" :autosize="{ minRows: 1, maxRows: 5 }" size="mini" />
            </el-form-item>
          </div>
        </template>
      </cardTitleCom>
      <!-- 表格商品信息卡片 -->
      <cardTitleCom cardTitle="商品信息" class="headGoodsTable">
        <template slot="leftCardTitle">
          <div class="marL10 x-f">
            <el-radio-group v-model="queryParams.type" size="mini" class="marR15">
              <el-radio-button :label="1">全部商品</el-radio-button>
              <el-radio-button :label="2">整单原料</el-radio-button>
              <el-radio-button :label="3">整单半成品</el-radio-button>
            </el-radio-group>
            <el-input v-model="queryParams.query" size="mini" class="marR10" style="width:150px"
              placeholder="请输入原料名称" />
            <el-button size="mini" type="primary">搜索</el-button>
          </div>
        </template>
        <template slot="rightCardTitle">
          <div class="marR10 x-f">
            <el-button size="mini" type="primary" @click="handleEvent('refreshStock')"
              :disabled='disabled'>刷新库存数</el-button>
            <!-- <el-button size="mini" type="primary" @click="handleEvent('semiFinishedProduct')"
              :disabled='disabled||checkRowIndex==undefined'>保留半成品库存</el-button>
            <el-button :disabled='disabled||checkRowIndex==undefined' size="mini" type="primary"
              @click="handleEvent('rawMaterial')">保留原料库存</el-button> -->
          </div>
        </template>
        <template slot="cardContent">
          <!-- 商品信息按钮和表格整体盒子 -->
          <div class="goodsMessage">
            <vxe-table @cell-mouseenter="cellMouseEnter" @cell-mouseleave="cellMouseLeave"
              @radio-change="radioChangeEvent" border show-overflow show-header-overflow ref="multipleTable"
              :data="virtualDetails" :loading="loadingTable" max-height="560px" height="560px"
              :checkbox-config="{ trigger: 'row' }" :row-config="{ isHover: true, height: 60 }"
              :sort-config="{ trigger: 'cell' }" :scroll-y="{ enabled: true }"
              :tree-config="{transform: true, rowField: 'billDetailId', parentField: 'parentBillDetailId'}"
              :column-config="{resizable: true}">
              <!-- <vxe-column align="center" type="radio" width="50" fixed="left" /> -->
              <vxe-column align="center" :title="tableCellLabel" width="80" fixed="left" type="seq">
                <template v-slot="scope">
                  <div>
                    <i v-show="scope.row.hoverRow&&!scope.row.usableBillDetailId" :disabled="disabled"
                      @click.stop="upRow('push', scope.row,scope)" class="el-icon-circle-plus operatePush"
                      :style="{marginRight:(scope.row.hoverRow&&scope.row.usableBillDetailId)?'15px':0}"></i>
                    <i v-show="scope.row.hoverRow&&scope.row.usableBillDetailId " :disabled="disabled"
                      @click.stop="upRow('del', scope.row,scope)" class="el-icon-remove operateDel"></i>
                    <div v-show="!scope.row.hoverRow">
                      {{ scope.seq }}
                    </div>
                  </div>
                </template>
              </vxe-column>
              <template v-if="matchMaterialForm.matchMaterialType == 1">
                <vxe-column title="商品编码" width="180" align="center" field="goodsNo">
                  <template slot-scope="scope">
                    <span v-if="scope.row.planGoodsInfo">{{scope.row.planGoodsInfo.goodsNo}}</span>
                  </template>
                </vxe-column>
                <vxe-column title="商品名称" align="center" field="goodsName" width="160">
                  <template slot-scope="scope">
                    <span v-if="scope.row.planGoodsInfo">{{scope.row.planGoodsInfo.goodsName}}</span>
                  </template>
                </vxe-column>
                <vxe-column title="单位" align="center" field="unitName" width="110">
                  <template slot-scope="scope">
                    <span v-if="scope.row.planGoodsInfo">{{scope.row.planGoodsInfo.unitName}}</span>
                  </template>
                </vxe-column>
                <vxe-column title="商品数量" align="center" field="planUnitQty" width="160" />
                <vxe-column title="商品条码" align="center" field="barcode" width="160">
                  <template slot-scope="scope">
                    <span v-if="scope.row.planGoodsInfo">{{scope.row.planGoodsInfo.barcode}}</span>
                  </template>
                </vxe-column>
              </template>
              <vxe-column title="原料编码" align="center" field="goodsNo" width="160" tree-node>
                <template slot-scope="scope">
                  <span v-if="scope.row.goodsInfo">{{scope.row.goodsInfo.goodsNo}}</span>
                </template>
              </vxe-column>
              <vxe-column title="原料名称" align="center" field="goodsName" width="160">
                <template slot-scope="scope">
                  <span v-if="scope.row.goodsInfo">{{scope.row.goodsInfo.goodsName}}</span>
                </template>
              </vxe-column>
              <vxe-column title="规格" align="center" field="goodsSpec" width="120">
                <template slot-scope="scope">
                  <span v-if="scope.row.goodsInfo">{{scope.row.goodsInfo.goodsSpec}}</span>
                </template>
              </vxe-column>
              <vxe-column title="单位" align="center" field="unitName" width="110">
                <template slot-scope="scope">
                  <span v-if="scope.row.goodsInfo">{{scope.row.goodsInfo.unitName}}</span>
                </template>
              </vxe-column>
              <vxe-column title="启用被代替物料" align="center" field="isUseUsable" width="130">
                <template slot-scope="scope">
                  <div class="x-fc">
                    <el-checkbox v-model="scope.row.isUseUsable" v-if="!scope.row.usableBillDetailId "
                      :disabled='isDisabled(scope.row)' />
                    <el-button type="primary" size="mini" @click="handOpenDia(scope.row)" v-else>选择替代商品</el-button>
                  </div>
                </template>
              </vxe-column>
              <vxe-column title="被代替原料编码" align="center" field="goodsNo" width="160">
                <template slot-scope="scope">
                  <span v-if="scope.row.usableGoodsInfo">{{scope.row.usableGoodsInfo.goodsNo}}</span>
                </template>
              </vxe-column>
              <vxe-column title="被代替原料名称" align="center" field="goodsName" width="160">
                <template slot-scope="scope">
                  <span v-if="scope.row.usableGoodsInfo">{{scope.row.usableGoodsInfo.goodsName}}</span>
                </template>
              </vxe-column>
              <vxe-column title="规格" align="center" field="goodsSpec" width="120">
                <template slot-scope="scope">
                  <span v-if="scope.row.usableGoodsInfo">{{scope.row.usableGoodsInfo.goodsSpec}}</span>
                </template>
              </vxe-column>
              <vxe-column title="单位" align="center" field="unitName" width="110">
                <template slot-scope="scope">
                  <span v-if="scope.row.usableGoodsInfo">{{scope.row.usableGoodsInfo.unitName}}</span>
                </template>
              </vxe-column>
              <vxe-column title="被代替数量" align="center" field="usableBasUnitQty" width="130">
                <template slot="header">
                  <span>被代替数量</span>
                </template>
                <template slot-scope="scope">
                  <!-- <el-form-item :prop="'details.' + scope.rowIndex + '.usableBasUnitQty'"
                    :rules="rules[`details.usableBasUnitQty`]"> -->
                  <el-input v-model="scope.row.usableBasUnitQty" @keyup.native="onkeyupSubstitutedQuantity(scope.row)"
                    :disabled="disabled" size="mini" type="number" maxlength="7" @input="
                        limitInputlength(scope.row.usableBasUnitQty, scope.row, 'usableBasUnitQty', true)
                      " :sunyunFormat="(v) => $syInput(1, v)" v-if="scope.row.usableBillDetailId "
                      
                      :ref="'usableBasUnitQty'+'_'+scope.rowIndex +'_15'"
                      @keydown.native.up.prevent="handleTableKeydown($event,scope.row, 'usableBasUnitQty',scope.rowIndex,15)"
                      @keydown.native.down.prevent="handleTableKeydown($event,scope.row, 'usableBasUnitQty',scope.rowIndex,15)"
                      @keydown.native.left.prevent="handleTableKeydown($event,scope.row, 'usableBasUnitQty',scope.rowIndex,15)"
                      @keydown.native.right.prevent="handleTableKeydown($event,scope.row, 'usableBasUnitQty',scope.rowIndex,15)"
                      @keydown.native.enter.prevent="handleTableKeydown($event,scope.row, 'usableBasUnitQty',scope.rowIndex,15)" 
                      ></el-input>
                  <!-- </el-form-item> -->
                </template>
              </vxe-column>
              <vxe-column title="需求量" align="center" field="basUnitQty" width="120" />
              <vxe-column title="配料间库存" align="center" field="matchStoreQty" width="120" />
              <vxe-column title="原料库存" align="center" field="materialStoreQty" width="120" />
              <vxe-column title="保留原料库存" align="center" field="materialStoreProtectQty" width="120">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.materialStoreProtectQty"
                    @keyup.native="handleEvent('rawMaterial',scope.row)" :disabled="disabled" size="mini" type="number"
                    maxlength="7" @input="
                        limitInputlength(scope.row.materialStoreProtectQty, scope.row, 'materialStoreProtectQty', true)
                      " :sunyunFormat="(v) => $syInput(1, v)"
                      :ref="'materialStoreProtectQty'+'_'+scope.rowIndex +'_19'"
                      @keydown.native.up.prevent="handleTableKeydown($event,scope.row, 'materialStoreProtectQty',scope.rowIndex,19)"
                      @keydown.native.down.prevent="handleTableKeydown($event,scope.row, 'materialStoreProtectQty',scope.rowIndex,19)"
                      @keydown.native.left.prevent="handleTableKeydown($event,scope.row, 'materialStoreProtectQty',scope.rowIndex,19)"
                      @keydown.native.right.prevent="handleTableKeydown($event,scope.row, 'materialStoreProtectQty',scope.rowIndex,19)"
                      @keydown.native.enter.prevent="handleTableKeydown($event,scope.row, 'materialStoreProtectQty',scope.rowIndex,19)"
                      ></el-input>
                </template>
              </vxe-column>
              <vxe-column title="半成品库存" align="center" field="halfStoreQty" width="120" />
              <vxe-column title="保留半成品库存" align="center" field="halfStoreProtectQty" width="120">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.halfStoreProtectQty"
                    @keyup.native="handleEvent('semiFinishedProduct',scope.row)" :disabled="disabled" size="mini"
                    type="number" maxlength="7" @input="
                        limitInputlength(scope.row.halfStoreProtectQty, scope.row, 'halfStoreProtectQty', true)
                      " :sunyunFormat="(v) => $syInput(1, v)"
                      :ref="'halfStoreProtectQty'+'_'+scope.rowIndex +'_21'"
                      @keydown.native.up.prevent="handleTableKeydown($event,scope.row, 'halfStoreProtectQty',scope.rowIndex,21)"
                      @keydown.native.down.prevent="handleTableKeydown($event,scope.row, 'halfStoreProtectQty',scope.rowIndex,21)"
                      @keydown.native.left.prevent="handleTableKeydown($event,scope.row, 'halfStoreProtectQty',scope.rowIndex,21)"
                      @keydown.native.right.prevent="handleTableKeydown($event,scope.row, 'halfStoreProtectQty',scope.rowIndex,21)"
                      @keydown.native.enter.prevent="handleTableKeydown($event,scope.row, 'halfStoreProtectQty',scope.rowIndex,21)"
                      ></el-input>
                </template>
              </vxe-column>
              <vxe-column title="库存可用总数" align="center" field="canUseStoreQty" width="120" />
              <vxe-column title="差异数" align="center" field="exoDiffBasUnitQty" width="120" />
              <vxe-column title="实际差异数" align="center" field="factDiffBasUnitQty" width="120" />
              <vxe-column title="领用基本单位数量" align="center" field="factLeadBasUnitQty" width="140" />
              <vxe-column title="领用数量" align="center" field="factLeadUnitQty" width="130">
                <template slot="header">
                  <i style="color: #ff4949">* </i>
                  <span>领用数量</span>
                </template>
                <template slot-scope="scope">
                  <!-- <el-form-item :prop="'details.' + scope.rowIndex + '.factLeadUnitQty'"
                    :rules="rules[`details.factLeadUnitQty`]"> -->
                  <el-input v-model="scope.row.factLeadUnitQty" @keyup.native="onkeyupUnitQty(scope.row)"
                    :disabled="disabled" size="mini" maxlength="7" type="number" @input="
                        limitInputlength(scope.row.factLeadUnitQty, scope.row, 'unitQty', true)
                      "
                      
                      :ref="'unitQty'+'_'+scope.rowIndex +'_26'"
                      @keydown.native.up.prevent="handleTableKeydown($event,scope.row, 'unitQty',scope.rowIndex,26)"
                      @keydown.native.down.prevent="handleTableKeydown($event,scope.row, 'unitQty',scope.rowIndex,26)"
                      @keydown.native.left.prevent="handleTableKeydown($event,scope.row, 'unitQty',scope.rowIndex,26)"
                      @keydown.native.right.prevent="handleTableKeydown($event,scope.row, 'unitQty',scope.rowIndex,26)"
                      @keydown.native.enter.prevent="handleTableKeydown($event,scope.row, 'unitQty',scope.rowIndex,26)"
                      ></el-input>
                  <!-- </el-form-item> -->
                </template>
              </vxe-column>
              <vxe-column title="领用单位" align="center" field="factLeadUnitId" width="120">
                <template slot="header">
                  <i style="color: #ff4949">* </i>
                  <span>领用单位</span>
                </template>
                <template slot-scope="scope">
                  <!-- <el-form-item> -->
                  <el-select v-model="scope.row.factLeadUnitId" :disabled="disabled" size="mini"
                  :ref="'factLeadUnitId'+'_'+scope.rowIndex +'_27'"
                      @keydown.native="handleTableKeydown($event,scope.row,'factLeadUnitId', scope.rowIndex,27)"
                    @change="onkeyupUnitQty(scope.row)">
                    <el-option v-for="item in scope.row.factLeadUnits" :key="item.unitId" :label="item.unitName"
                      :value="item.unitId" />
                  </el-select>
                  <!-- </el-form-item> -->
                </template>
              </vxe-column>
              <template #empty>
                <span>
                  <p>暂无数据</p>
                </span>
              </template>
            </vxe-table>
          </div>
        </template>
      </cardTitleCom>
    </div>
    <!-- 弹窗 -->
    <Dialog ref="tablePage" :options.sync="dialogOptions" @handleEvent="handleEvent" />
    <!-- 对话框 -->
    <c-dialog :title="title" :width="500" :showDialog.sync="dialogFormVisible">
      <template #content>
        <el-form ref="ruleForm" label-width='120px'>
          <el-form-item label="保留库存数量">
            <el-input type="number" maxlength="9" v-model="inventoryNum" autocomplete="off"
              placeholder="请输入保留库存数量"></el-input>
          </el-form-item>
        </el-form>
      </template>
      <template #buttonList>
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="handleEvent('submitInventory')">确 定</el-button>
      </template>
    </c-dialog>
  </el-form>
</template>

<script>
import CDialog from "@/components/dialogTemplate/dialog/index.vue";
import { getNewDate } from "@/utils/newDate"; //单据日期
import cardTitleCom from "@/views/components/cardTitleCom"; //卡片标题
import { getBillNo } from "@/api/codeRule"; //单据号
import {
  addMixMaterial,
  getDetailInfo,
  modifyMixMaterial,
  modifyBillStatus,
  mixMaterial,
  refreshStock,
  replaceMaterial
} from "@/api/produce/bill/matchMaterial"; //生产配料
import topOperatingButton from "@/views/components/topOperatingButton"; //顶部按钮
import Dialog from "@/components/Dialog";
import { limitInputlength, fcount, isNumber, deepCopy, getUid } from "@/utils";
import {
  getSelectAssign, //赋值
} from "@/utils/orderTableSelect.js"; //表格的下拉框公共配置
import { produceDeptList } from "@/api/produce/sets/produceDept"; //生产工厂
import SelectRemote from "@/components/tablePage/select/select-remote/index.vue";
import SelectLocal from "@/components/tablePage/select/select-local/index.vue";
import tableKeydown from "@/utils/mixin/tableKeydown";
export default {
  name: "MatchMaterialDetail",
  mixins: [tableKeydown],
  components: {
    CDialog,
    cardTitleCom,
    topOperatingButton,
    SelectRemote,
    SelectLocal,
    Dialog,
  },
  data() {
    return {
      checkRowIndex: undefined, //选中行的下标
      checkRow: undefined,  //选中行
      inventoryNum: '',//保留库存数量
      dialogFormVisible: false, //弹窗
      title: '保留半成品库存',
      diaTypeInfo: { type: 1 },//弹窗类型
      tableCellLabel: "序号",
      queryParams: {
        type: 1
      },
      //表格遮罩
      loadingTable: false,
      //弹窗配置
      dialogOptions: {
        title: "选择单据",
        width: 1250,
        show: false,
        type: "TreeAndTable",
        formData: this.$dialog({ key: "matchMaterialImport" }),
      },
      showImportBill: false, //引入源单按钮
      showAdd: false, //新增按钮
      showAudit: true, //审核按钮禁用状态
      //表单禁用
      disabled: false,
      // 遮罩层
      loading: false,
      //旧的表单
      oldForm: {},
      // 表单参数
      matchMaterialForm: {
        matchMaterialType: 1,
        billDate: "", //单据日期
        billNo: undefined, //单据编号
        billStatus: "", //单据审核状态
        //单据表格-订单明细
        details: [],
      },
      // 表单校验
      rules: {
        billNo: [
          {
            required: true,
            message: "请到编码规则配置单据类型",
            trigger: ["blur", "change"],
          },
        ],
        billDate: [
          {
            required: true,
            message: "单据日期不能为空",
            trigger: ["blur", "change"],
          },
        ],
        produceDeptId: [
          {
            required: true,
            message: "配料工厂不能为空",
            trigger: ["blur", "change"],
          },
        ],
        "details.goodsNo": [
          {
            required: true,
            message: "商品编码不能为空",
            trigger: ["blur", "change"],
          },
        ],
        "details.goodsSpec": [
          {
            required: true,
            message: "规格不能为空",
            trigger: ["blur", "change"],
          },
        ],
        "details.unitId": [
          {
            required: true,
            message: "单位不能为空",
            trigger: ["blur", "change"],
          },
        ],
        "details.outStoreId": [
          {
            required: true,
            message: "配料仓库不能为空",
            trigger: ["blur", "change"],
          },
        ],
        "details.unitQty": [
          {
            required: true,
            message: "领用数量不能为空",
            trigger: ["blur", "change"],
          },
        ],
      },
      canKeyTableColumnList:[
        {key:'usableBasUnitQty',columnindex:15},
        {key:'materialStoreProtectQty',columnindex:19},
        {key:'halfStoreProtectQty',columnindex:21},
        {key:'unitQty',columnindex:26},
        {key:'factLeadUnitId',columnindex:27,type:'select'},
      ],//可以键盘导航的表格列详情
    };
  },
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      vm.loading = true;
      // 重置数据
      vm.reset();
      //初始化数据
      await vm.getInitializeData();
      // 访问组件实例 vm 中的数据和方法
      if (vm.$route.query.type === "Update") {
        if (vm.$route.query.billId) {
          //获取单据详情
          const { data } = await getDetailInfo(vm.$route.query.billId);
          //单据赋值
          vm.matchMaterialForm = data;
          //原始单据赋值
          vm.oldForm = JSON.parse(JSON.stringify(vm.matchMaterialForm));
        }
      } else {
        await vm.getOrderBillNo();
        //判断是否有以前新增时保存的数据
        // if (JSON.stringify(vm.oldForm) != "{}") {
        //   vm.matchMaterialForm = vm.oldForm;
        // }
        // vm.oldForm = JSON.parse(JSON.stringify(vm.matchMaterialForm));
      }
      vm.loading = false;
    });
  },
  async beforeRouteLeave(to, from, next) {
    // 在用户离开二级路由前弹出询问框
    if (this.matchMaterialForm.billId) {
      // this.oldForm = {};
    } else {
      // this.oldForm = JSON.parse(JSON.stringify(this.matchMaterialForm));
    }
    next();
  },
  beforeDestroy() {
    // 在页面销毁前执行的逻辑
    this.oldForm = {};
  },
  watch: {
    //侦听单据状态
    "matchMaterialForm.billStatus": {
      handler(newVal) {
        if (this.matchMaterialForm.billStatus == "2") {
          //引入源单按钮
          this.showImportBill = false;
          //新增按钮
          this.showAdd = true;

          //禁用输入按钮、保存按钮
          this.disabled = true;
          //打开审核按钮
          this.showAudit = false;
        } else if (this.matchMaterialForm.billStatus == "3") {
          //引入源单按钮
          this.showImportBill = false;
          //新增按钮
          this.showAdd = false;

          //禁用审核按钮
          this.showAudit = true;
          //禁止全部输入按钮
          this.disabled = true;
        } else if (this.matchMaterialForm.billStatus == "0") {
          //引入源单按钮
          this.showImportBill = false;
          //新增按钮
          this.showAdd = false;

          //禁用审核按钮
          this.showAudit = false;

          //恢复全部输入
          this.disabled = false;
        } else if (this.matchMaterialForm.billStatus == "4") {
          //引入源单按钮
          this.showImportBill = false;
          //新增按钮
          this.showAdd = false;

          //禁用审核按钮
          this.showAudit = false;

          //恢复全部输入
          this.disabled = false;
        } else if (this.matchMaterialForm.billStatus == "") {
          //引入源单按钮
          this.showImportBill = true;
          //新增按钮
          this.showAdd = false;

          //禁用审核按钮
          this.showAudit = true;
          //恢复全部输入
          this.disabled = false;
        }
      },
      immediate: true,
    },
    virtualDetails:{
      handler(val) {
        this.hasTableKey = val;
      },
      deep: true,
    }
  },
  beforeDestroy() {
    // 在页面销毁前执行的逻辑
    this.oldForm = {};
  },
  computed: {
    virtualDetails() {
      //goodsType  商品类型(0 库存商品、1 成品、2 半成品、3 原料、4 低值易耗品、5 其他收费项目等)
      //type  筛选商品(1全部 2原料 3半成品)
      return this.matchMaterialForm.details.filter(data => {
        if (!this.queryParams.query || data.goodsInfo.goodsName.includes(this.queryParams.query)) {
          if (this.queryParams.type == 1) {
            return data
          } else if (this.queryParams.type == 2 && data.goodsInfo.goodsType == 3) {
            return data
          } else if (this.queryParams.type == 3 && data.goodsInfo.goodsType == 2) {
            return data
          }
        }
      })
    }
  },
  methods: {
    rowDrop({ row }) {
      console.log('k', k);
    },
    isDisabled(row) {
      let isBoolean = this.matchMaterialForm.details.find(item => item.usableBillDetailId == row.billDetailId)
      return (isBoolean || this.disabled) ? true : false
    },
    radioChangeEvent({ val, oldValue, row, rowIndex, $rowIndex }) {
      console.log('row', row);
      console.log('$rowIndex', $rowIndex);
      this.checkRow = deepCopy(row)
      this.checkRowIndex = $rowIndex
    },
    cancel() {
      this.dialogFormVisible = false
      this.inventoryNum = ''
    },
    handOpenDia(row) {
      console.log('row', row);
      this.dialogOptions.formData = this.$dialog({ key: 'instead' })
      this.dialogOptions.formData.table.pagination = {
        ...this.dialogOptions.formData.table.pagination,
        goodsId: row.usableGoodsId,
      };
      this.dialogOptions.show = true;
      this.diaTypeInfo = {
        type: 2,
        billId: this.matchMaterialForm.billId,
        billDetailId: row.usableBillDetailId,
        replaceRowIndex: row.replaceRowIndex,
        originalBasUnitQty: row.originalBasUnitQty,
        planGoodsId: row.planGoodsId,
        planUnitId: row.planUnitId,
        planUnitQty: row.planUnitQtyNew,
        batchNo: row.batchNo,
        goodsId: row.goodsId,
        unitId: row.unitId,
        parentBillDetailId: row.parentBillDetailId,
      }
    },
    //表格增加/减少一行方法
    upRow(name, row, scope) {
      console.log('111', name, row, scope);
      if (name === "push") {
        console.log('dasda1', deepCopy(this.matchMaterialForm.details));
        let index = this.matchMaterialForm.details.findIndex(item => item.billDetailId == row.billDetailId);
        this.matchMaterialForm.details.splice(index + 1, 0, {
          isImportGoods: false,
          hoverRow: false,
          usableGoodsId: row?.goodsId,
          usableUnitId: row?.goodsInfo?.unitId,
          usableBillDetailId: row?.billDetailId,
          replaceRowIndex: index,
          parentBillDetailId: row.parentBillDetailId, //ss
          // billDetailId: getUid(),//ss
          originalBasUnitQty: row.originalBasUnitQty,
          planGoodsId: row.planGoodsId,
          planUnitId: row.planUnitId,
          planUnitQtyNew: row.planUnitQty,
          batchNo: row.batchNo,
          goodsId: row.goodsId,
          unitId: row.unitId,
          usableGoodsInfo: {
            goodsNo: row.goodsInfo?.goodsNo,
            goodsName: row.goodsInfo?.goodsName,
            goodsSpec: row.goodsInfo?.goodsSpec,
            unitName: row.goodsInfo?.unitName
          }
        });
        console.log('dasda2', deepCopy(this.matchMaterialForm.details));
      } else {
        if (row?.isImportGoods) {
          this.$message.error("引入源单的商品不允许删除！");
          return;
        }
        this.matchMaterialForm.details = this.matchMaterialForm.details.filter(item => item.billDetailId != row.billDetailId)
      }
    },
    //表格行hover时,显示操作加减号
    cellMouseEnter({ row }) {
      if (row.isUseUsable || row.usableBillDetailId) {
        // let isbo = this.matchMaterialForm.details.some((v) => v.hoverRow);
        // if (isbo) {
        //   this.matchMaterialForm.details.forEach((v) => {
        //     if (v.hoverRow) {
        //       v.hoverRow = false;
        //     }
        //   });
        // }
        this.tableCellLabel = "操作";
        this.$set(row, "hoverRow", true);
      }
    },
    //表格行离开hover时,不显示操作加减号,显示序号
    cellMouseLeave({ row }) {
      this.tableCellLabel = "序号";
      // 移除hover的事件
      this.$set(row, "hoverRow", false);
    },
    limitInputlength,
    changeProduceGroupChange(v) {
    },
    //打开弹窗
    getImportOrder() {
      const form = this.matchMaterialForm;
      const validations = {
        produceDeptId: "请选择生产工厂！",
        matchStoreId: "请选择配料仓库！",
        materialStoreId: "请选择原料仓库！",
        halfStoreId: "请选择半成品仓库！",
        matchMaterialType: "请选择配料类型！"
      };
      for (const key in validations) {
        if (!form[key]) {
          return this.$message.error(validations[key]);
        }
      }
      this.dialogOptions.formData = this.$dialog({ key: 'matchMaterialImport' })
      this.dialogOptions.formData.table.pagination = {
        ...this.dialogOptions.formData.table.pagination,
        produceDeptIds: [this.matchMaterialForm.produceDeptId],
      };
      this.dialogOptions.show = true;
      this.diaTypeInfo = { type: 1 }
    },
    //弹窗确定事件
    async handleEvent(type, row) {
      switch (type) {
        case "dialogChange":
          console.log('dialogChange', this.dialogOptions.formData.table.check);
          if (this.diaTypeInfo.type == 1) {
            let planBillDetailIds = this.dialogOptions.formData.table.check.map(v => v.billDetailId)
            const { matchStoreId, materialStoreId, halfStoreId, matchMaterialType, produceDeptId, billId } = this.matchMaterialForm
            let res = await mixMaterial({
              planBillDetailIds,
              matchStoreId,
              materialStoreId,
              halfStoreId,
              matchMaterialType,
              produceDeptId,
              billId,
            })
            res.data.details = res.data.details.map(item => {
              item.isUseUsable = false
              item.isImportGoods = true
              item.hoverRow = false
              return item
            })
            res.data.details = res.data.details.map(item => {
              this.onkeyupUnitQty(item, res.data.details)
              return item
            })
            this.matchMaterialForm.details = res.data.details
            this.matchMaterialForm.billId = res.data.billId
            this.matchMaterialForm.importDetails = res.data.importDetails
            this.$message.success("引入源单成功");
            this.checkRowIndex = undefined
            this.checkRow = undefined
          } else if (this.diaTypeInfo.type == 2) {
            let arr = []
            this.dialogOptions.formData.table.check.forEach(item => {
              arr.push({
                billId: this.matchMaterialForm.billId,
                billDetailId: this.diaTypeInfo.billDetailId,
                usableLineId: item.lineId,
                originalBasUnitQty: this.diaTypeInfo.originalBasUnitQty,
                planGoodsId: this.diaTypeInfo.planGoodsId,
                planUnitId: this.diaTypeInfo.planUnitId,
                planUnitQty: this.diaTypeInfo.planUnitQty,
                batchNo: this.diaTypeInfo.batchNo,
                goodsId: this.diaTypeInfo.goodsId,
                unitId: this.diaTypeInfo.unitId,
                matchMaterialType: this.matchMaterialForm.matchMaterialType,
                materialStoreId: this.matchMaterialForm.materialStoreId,
                matchStoreId: this.matchMaterialForm.matchStoreId,
                halfStoreId: this.matchMaterialForm.halfStoreId,
                parentBillDetailId: this.diaTypeInfo.parentBillDetailId,
              })
            })
            console.log('diaTypeInfo', this.diaTypeInfo);
            const res = await replaceMaterial(arr)
            console.log('this.diaTypeInfo.replaceRowIndex', this.diaTypeInfo.replaceRowIndex);
            res.data = res.data.map(item => {
              item.hoverRow = false
              item.replaceRowIndex = this.diaTypeInfo.replaceRowIndex
              return item
            })
            this.matchMaterialForm.details.splice(this.diaTypeInfo.replaceRowIndex + 1, 0, ...res.data);
            this.matchMaterialForm.details = this.matchMaterialForm.details.filter(x => x.billDetailId)
            this.$message.success("替换原料成功");
            // res.data
          }
          break;
        case "refreshStock":
          if (this.matchMaterialForm.billId) {
            const res = await refreshStock({ billId: this.matchMaterialForm.billId })
            this.matchMaterialForm.details = this.matchMaterialForm.details.map(item => {
              let obj = res.data.find(v => v.billDetailId == item.billDetailId)
              if (obj) {
                item.matchStoreQty = obj.matchStoreQty     //配料间库存
                item.materialStoreQty = obj.materialStoreQty  //原料库存
                item.halfStoreQty = obj.halfStoreQty  //半成品库存
                item.canUseStoreQty = obj.canUseStoreQty  //库存可用总数
                item.exoDiffBasUnitQty = obj.exoDiffBasUnitQty  //差异数
                this.onkeyupUnitQty(item)
                return item
              }
              return item
            })
            this.$message.success("刷新库存成功");
          } else {
            this.$message.error("没有单据ID，刷新库存失败");
          }
          break;
        case "semiFinishedProduct":
          //半成品
          this.$set(row, 'halfStoreQty', fcount([row.originalHalfStoreQty, row.halfStoreProtectQty], '-'))  //半成品库存
          //  配料间 matchStoreQty  原料库存 materialStoreQty  半成品库存 halfStoreQty
          let unitQty = fcount([fcount([row.matchStoreQty, row.materialStoreQty], '+'), row.halfStoreQty], '+')
          this.$set(row, 'canUseStoreQty', unitQty)  //库存可用数
          this.$set(row, 'exoDiffBasUnitQty', fcount([row.basUnitQty, row.canUseStoreQty], '-'))  //差异
          this.onkeyupUnitQty(row)
          // this.title = '保留半成品库存'
          // this.cancel()
          // this.dialogFormVisible = true
          break;
        case "rawMaterial":
          //原料
          this.$set(row, 'materialStoreQty', fcount([row.originalMaterialStoreQty, row.materialStoreProtectQty], '-'))  //半成品库存
          //  配料间 matchStoreQty  原料库存 materialStoreQty  半成品库存 halfStoreQty
          let unitQty2 = fcount([fcount([row.matchStoreQty, row.materialStoreQty], '+'), row.halfStoreQty], '+')
          this.$set(row, 'canUseStoreQty', unitQty2)  //库存可用数
          this.$set(row, 'exoDiffBasUnitQty', fcount([row.basUnitQty, row.canUseStoreQty], '-'))  //差异
          this.onkeyupUnitQty(row)
          // this.title = '保留原料库存'
          // this.cancel()
          // this.dialogFormVisible = true
          break;
        case 'submitInventory':
          if (!this.inventoryNum) return this.$message.error('请输入半成品数量')

          let obj = this.matchMaterialForm.details[this.checkRowIndex]
          if (this.title == '保留半成品库存') {
            obj.halfStoreQty = fcount([obj.originalHalfStoreQty, this.inventoryNum], '-')  //半成品库存
          } else {
            obj.materialStoreQty = fcount([obj.originalMaterialStoreQty, this.inventoryNum], '-')  //原料
          }
          obj.canUseStoreQty = fcount([obj.canUseStoreQty, this.inventoryNum], '-')  //库存可用数
          obj.exoDiffBasUnitQty = fcount([obj.basUnitQty, obj.canUseStoreQty], '-')  //差异
          this.onkeyupUnitQty(obj)
          this.cancel()
          break;
        default:
          break;
      }
    },
    //计算
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        const other = ["importUnitQty", "unitQty", "unitMoney"];
        const [key, orderStoreId] = column.property?.split?.(".") || [];
        if (other.includes(column.property) || other.includes(key)) {
          let values = data.map((x) => {
            let num = Number(x[column.property] || 0);
            if (orderStoreId) {
              num = Number(
                x.storeItemCache?.find?.((x) => x.orderStoreId === orderStoreId)?.[key] ||
                0
              );
            }
            return num;
          });
          sums[index] = values.reduce(
            (p, c) => fcount([p, isNumber(Number(c)) ? Number(c) : 0], "+"),
            0
          );
        } else {
          sums[index] = "";
        }
      });
      return sums;
    },
    //退出
    getQuit() {
      // 关闭当前tab页签，打开新页签
      this.$tab.closeOpenPage({ path: "/produce/bill/matchMaterial" });
    },
    //新增按钮
    async handleAdd() {
      this.loading = true;
      this.reset();
      await this.getOrderBillNo();
      await this.getInitializeData();
      this.oldForm = JSON.parse(JSON.stringify(this.matchMaterialForm));
      this.loading = false;
    },
    //被替代
    onkeyupSubstitutedQuantity(row) {
      //被替代数量  usableBasUnitQty  原需求量  originalBasUnitQty   需求量  basUnitQty  替换比例  usableExchangeScale
      row.originalBasUnitQty
      this.$set(row, "basUnitQty", fcount([row.usableBasUnitQty, row.usableExchangeScale], "*"))  //需求量
      this.$set(row, "exoDiffBasUnitQty", fcount([row.basUnitQty, row.canUseStoreQty], "-"))  //差异
      let fIndex = this.matchMaterialForm.details.findIndex(item => item.billDetailId == row.usableBillDetailId)
      if (fIndex > -1) {
        let info = this.matchMaterialForm.details[fIndex]
        let totalArr = this.matchMaterialForm.details.filter(v => v.usableBillDetailId == info.billDetailId)
        let sum = totalArr.reduce((accumulator, currentValue) => {
          return fcount([accumulator, currentValue.usableBasUnitQty], "+");
        }, 0);
        this.$set(info, "basUnitQty", fcount([info.originalBasUnitQty, sum], "-"))  //需求量
        this.$set(info, "exoDiffBasUnitQty", fcount([info.basUnitQty, info.canUseStoreQty], "-"))  //差异
      }
    },
    //领用数量
    onkeyupUnitQty(row, details = this.matchMaterialForm.details) {
      let factLeadUnitQty = row.factLeadUnitQty      //领用数量
      let basUnitQty = row.factLeadUnits.find(v => row.factLeadUnitId == v.unitId)?.basUnitQty || 0  //比例
      let factDiffBasUnitQty = 0    //实际差异
      let factLeadBasUnitQty = fcount([factLeadUnitQty, basUnitQty], "*")  //领用基本单位数量
      factDiffBasUnitQty = fcount([factLeadBasUnitQty, row.canUseStoreQty], "-")  //实际差异数量
      this.$set(row, "factLeadBasUnitQty", factLeadBasUnitQty)  //领用基本单位数量
      this.$set(row, "factDiffBasUnitQty", factDiffBasUnitQty)  //实际差异数量
      this.updateChildItem(row, row.billDetailId, details);
    },
    // 递归函数，用于更新特定父级下的所有子级  
    updateChildItem(row, parentId, details) {
      // console.log('row', deepCopy(row));
      // console.log('details', deepCopy(details));
      let childIndex = details.findIndex(v => v.parentBillDetailId == row.billDetailId)
      // console.log('childIndex', deepCopy(childIndex));
      if (childIndex < 0) return
      for (let i = 0; i < details.length; i++) {
        const item = details[i];
        if (item.parentBillDetailId === parentId) {
          // 找到子级  
          // 更新子级的领用数量等属性  
          //找到子级  子级领用数 = 父级实际差异数 * 子配方单个用量
          // console.log('baseUnitOneUseQty', deepCopy(item.baseUnitOneUseQty));
          item.factLeadUnitQty = fcount([row.factDiffBasUnitQty, item.baseUnitOneUseQty], "*");
          // console.log('factLeadUnitQty', deepCopy(item.factLeadUnitQty));
          let factLeadUnitQty = item.factLeadUnitQty;//领用数量
          let basUnitQty = item.factLeadUnits?.find(v => item.factLeadUnitId === v.unitId)?.basUnitQty || 0;//单位比例
          // console.log('basUnitQty', deepCopy(basUnitQty));
          item.factLeadBasUnitQty = fcount([factLeadUnitQty, basUnitQty], "*");  //领用基本单位数量
          // console.log('factLeadBasUnitQty', deepCopy(item.factLeadBasUnitQty));
          item.factDiffBasUnitQty = fcount([item.factLeadBasUnitQty, item.canUseStoreQty], "-");  //实际差异数量

          // 如果还有子级，递归调用  
          this.updateChildItem(item, item.billDetailId, details);
        }
      }
    },
    // 表单重置
    reset() {
      //新增按钮
      this.showAdd = false;
      //保存禁用
      this.disabled = false;
      this.matchMaterialForm = {
        matchMaterialType: 1,
        billDate: "", //单据日期
        billNo: undefined, //单据编号
        billStatus: "", //单据审核状态
        //单据表格-订单明细
        details: [],
      };
      this.resetForm("form");
    },
    //单据编号
    async getOrderBillNo() {
      this.matchMaterialForm.billNo = await getBillNo(170206);
    },
    //审核/删除/终止事件
    async auditBill(name) {
      try {
        await this.$confirm(`确定${name}该订单, 是否继续?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "success",
        });

        if (name === "审核") {
          if (this.matchMaterialForm.billStatus != "0") {
            this.$message.error("此单据不是未审核的状态哦~");
            return;
          }
          await this.submitForm(true, "audit");
          const obj = {
            billIds: [this.matchMaterialForm.billId],
            billStatus: "2",
            billType: "170206",
          };
          //发送审核api
          const { data } = await modifyBillStatus(obj);
          this.matchMaterialForm = data;
          // 弹出提示
          // this.$message.success('审核成功')
        } else if (name === "反审核") {
          if (this.matchMaterialForm.billStatus != "2") {
            this.$message.error("此单据不是已审核的状态哦~");
            return;
          }
          const obj = {
            billIds: [this.matchMaterialForm.billId],
            billStatus: "0",
            billType: "170206",
          };
          //发送反审核api
          const { data } = await modifyBillStatus(obj);
          this.matchMaterialForm = data;
          // 弹出提示
          this.$message.success("反审核成功");
        }
      } catch { }
    },
    /** 提交按钮 */
    async submitForm(isBool, audit) {
      const valid = await this.$refs["form"].validate();
      try {
        this.loading = true;
        if (valid) {
          if (this.matchMaterialForm.createTime) {
            const res = await modifyMixMaterial(this.matchMaterialForm);
            //重新赋值给表单，可以把id赋值
            if (isBool) {
              this.matchMaterialForm = res.data;
              this.$modal.msgSuccess(audit ? "审核成功" : "修改单据成功");
            } else {
              await this.reset();
              await this.getOrderBillNo();
              this.$modal.msgSuccess("保存并新增单据成功");
            }
          } else {
            const { data } = await addMixMaterial(this.matchMaterialForm);
            //重新赋值给表单，可以把id赋值
            if (isBool) {
              this.matchMaterialForm = data;
              this.$modal.msgSuccess(audit ? "审核成功" : "新增单据成功");
            } else {
              await this.reset();
              await this.getOrderBillNo();
              this.$modal.msgSuccess("保存并修改单据成功");
            }
          }
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    //保存并新增单据
    async addBill() {
      this.$confirm("是否保存并新增单据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
      })
        .then(async () => {
          this.loading = true;
          this.submitForm(false);

          this.loading = false;
        })
        .catch();
    },
    //初始化页面数据
    async getInitializeData() {
      this.loading = true;
      //获取单据日期
      this.matchMaterialForm.billDate = getNewDate();
      const res = await produceDeptList({ produceDeptNo: "0000" });
      if (res.rows[0]?.produceDeptId) {
        //默认生产工厂
        this.$set(this.matchMaterialForm, "produceDeptId", res.rows[0]?.produceDeptId);
        //默认生产工厂名称
        this.$set(this.matchMaterialForm, "produceDeptName", res.rows[0]?.produceDeptName);
        // this.changeProduceGroupChange(res.rows[0]?.produceDeptId)
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped lang="scss">
//整体盒子
.wrap {
  padding: 40px 10px 1px 10px;
  background-color: #eaeaea;
  min-height: calc(100vh - 84px);
  .headGoodsTable {
    //商品表格卡片
    .goodsMessage {
      padding: 6px 10px;
      ::v-deep .el-form-item__content {
        margin-left: 0 !important;
      }
      ::v-deep .el-form-item__error {
        position: static;
      }
      ::v-deep .el-form-item {
        margin-bottom: 0;
      }
    }
  }
}
::v-deep .el-form-item {
  margin-bottom: 20px;
}
//输入框原生属性
/**
 * 解决el-input设置类型为number时，中文输入法光标上移问题
 **/
::v-deep .el-input__inner {
  line-height: 1px !important;
}

/**
 * 解决el-input设置类型为number时，去掉输入框后面上下箭头
 **/
::v-deep input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
::v-deep .el-input__suffix-inner {
  z-index: 11;
  height: 100%;
  display: inline-block;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
