var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      attrs: { model: _vm.planForm, "label-width": "96px", rules: _vm.rules },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "wrap",
          attrs: { id: "wrap" },
        },
        [
          _c("topOperatingButton", {
            attrs: {
              isImportOrderBtn: _vm.showImportBill,
              disabled: _vm.disabled,
              showAudit: _vm.showAudit,
              isAddBtn: _vm.showAdd,
              id: "topOperatingButton",
            },
            on: {
              submitForm: _vm.submitForm,
              addBill: _vm.addBill,
              auditBill: _vm.auditBill,
              handleAdd: _vm.handleAdd,
              getQuit: _vm.getQuit,
              getImportOrder: _vm.getImportOrder,
            },
          }),
          _c(
            "cardTitleCom",
            {
              attrs: {
                cardTitle: "基本信息",
                billStatus: _vm.planForm.billStatus,
                id: "basicInformation",
              },
            },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "x-w marT10" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "单据编号", prop: "billNo" } },
                      [
                        _c("el-input", {
                          staticClass: "inputWidth",
                          attrs: {
                            disabled: true,
                            placeholder: "单据编号",
                            size: "mini",
                          },
                          model: {
                            value: _vm.planForm.billNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.planForm, "billNo", $$v)
                            },
                            expression: "planForm.billNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "单据日期", prop: "billDate" } },
                      [
                        _c("el-date-picker", {
                          staticStyle: { width: "170px" },
                          attrs: {
                            type: "date",
                            placeholder: "单据日期",
                            disabled: _vm.disabled,
                            "value-format": "yyyy-MM-dd",
                            size: "mini",
                          },
                          model: {
                            value: _vm.planForm.billDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.planForm, "billDate", $$v)
                            },
                            expression: "planForm.billDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "生产日期", prop: "produceDate" } },
                      [
                        _c("el-date-picker", {
                          staticStyle: { width: "170px" },
                          attrs: {
                            type: "date",
                            placeholder: "生产日期",
                            disabled: _vm.disabled,
                            "value-format": "yyyy-MM-dd",
                            size: "mini",
                          },
                          model: {
                            value: _vm.planForm.produceDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.planForm, "produceDate", $$v)
                            },
                            expression: "planForm.produceDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "手工单据号", prop: "handBillNo" } },
                      [
                        _c("el-input", {
                          staticClass: "inputWidth",
                          attrs: {
                            disabled: _vm.disabled,
                            placeholder: "手工单据号",
                            size: "mini",
                          },
                          model: {
                            value: _vm.planForm.handBillNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.planForm, "handBillNo", $$v)
                            },
                            expression: "planForm.handBillNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "生产工厂", prop: "produceDeptId" } },
                      [
                        _c("SelectRemote", {
                          staticStyle: { width: "250px" },
                          attrs: {
                            option: _vm.$select({
                              key: "listProduceDept",
                              option: {
                                option: {
                                  disabled: _vm.disabled || _vm.isImportSource,
                                },
                              },
                            }).option,
                          },
                          on: { selectChange: _vm.changeProduceGroupChange },
                          model: {
                            value: _vm.planForm.produceDeptId,
                            callback: function ($$v) {
                              _vm.$set(_vm.planForm, "produceDeptId", $$v)
                            },
                            expression: "planForm.produceDeptId",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.isPlanGroup == "Y"
                      ? _c(
                          "el-form-item",
                          {
                            attrs: { label: "生产组", prop: "produceGroupId" },
                          },
                          [
                            _c("SelectRemote", {
                              staticStyle: { width: "250px" },
                              attrs: {
                                disabled:
                                  _vm.disabled || !_vm.planForm.produceDeptId,
                                option: _vm.$select({
                                  key: "listProduceGroup",
                                  option: {
                                    option: {
                                      remoteBody: {
                                        produceDeptId:
                                          _vm.planForm.produceDeptId,
                                      },
                                    },
                                  },
                                }).option,
                              },
                              on: { selectChange: _vm.produceGroupChange },
                              model: {
                                value: _vm.planForm.produceGroupId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.planForm, "produceGroupId", $$v)
                                },
                                expression: "planForm.produceGroupId",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "el-form-item",
                      { attrs: { label: "生产班次", prop: "produceOrderId" } },
                      [
                        _c("SelectRemote", {
                          staticStyle: { width: "250px" },
                          attrs: {
                            disabled:
                              _vm.disabled || !_vm.planForm.produceDeptId,
                            option: _vm.$select({
                              key: "listProduceOrder",
                              option: {
                                option: {
                                  remoteBody: {
                                    produceDeptId: _vm.planForm.produceDeptId,
                                  },
                                },
                              },
                            }).option,
                          },
                          model: {
                            value: _vm.planForm.produceOrderId,
                            callback: function ($$v) {
                              _vm.$set(_vm.planForm, "produceOrderId", $$v)
                            },
                            expression: "planForm.produceOrderId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "备注", prop: "billRemark" } },
                      [
                        _c("el-input", {
                          staticClass: "inputRemark",
                          attrs: {
                            disabled: _vm.disabled,
                            type: "textarea",
                            placeholder: "备注长度介于 1 和 80 字符之间",
                            maxlength: "80",
                            autosize: { minRows: 1, maxRows: 5 },
                            size: "mini",
                          },
                          model: {
                            value: _vm.planForm.billRemark,
                            callback: function ($$v) {
                              _vm.$set(_vm.planForm, "billRemark", $$v)
                            },
                            expression: "planForm.billRemark",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            { staticClass: "headGoodsTable", attrs: { cardTitle: "商品信息" } },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "goodsMessage" },
                  [
                    _c(
                      "vxe-table",
                      {
                        ref: "multipleTable",
                        attrs: {
                          border: "",
                          "show-footer": "",
                          "show-overflow": "",
                          "show-header-overflow": "",
                          data: _vm.planForm.detailItem,
                          loading: _vm.loadingTable,
                          "max-height": "560px",
                          height: "560px",
                          "checkbox-config": { trigger: "row" },
                          "row-config": { isHover: true, height: 60 },
                          "sort-config": { trigger: "cell" },
                          "scroll-y": { enabled: true },
                          "footer-method": _vm.footerMethod,
                        },
                      },
                      [
                        _c("vxe-column", {
                          attrs: {
                            align: "center",
                            title: _vm.tableCellLabel,
                            width: "80",
                            fixed: "left",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      on: {
                                        mouseenter: function ($event) {
                                          return _vm.cellMouseEnter(scope.row)
                                        },
                                        mouseleave: function ($event) {
                                          return _vm.cellMouseLeave(scope.row)
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: scope.row.hoverRow,
                                            expression: "scope.row.hoverRow",
                                          },
                                        ],
                                        staticClass:
                                          "el-icon-circle-plus operatePush",
                                        attrs: { disabled: _vm.disabled },
                                        on: {
                                          click: function ($event) {
                                            return _vm.row(
                                              "push",
                                              scope.rowIndex
                                            )
                                          },
                                        },
                                      }),
                                      _c("i", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: scope.row.hoverRow,
                                            expression: "scope.row.hoverRow",
                                          },
                                        ],
                                        staticClass:
                                          "el-icon-remove operateDel",
                                        attrs: { disabled: _vm.disabled },
                                        on: {
                                          click: function ($event) {
                                            return _vm.row(
                                              "del",
                                              scope.rowIndex
                                            )
                                          },
                                        },
                                      }),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: !scope.row.hoverRow,
                                              expression: "!scope.row.hoverRow",
                                            },
                                          ],
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(scope.rowIndex + 1) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c(
                          "vxe-column",
                          {
                            attrs: {
                              title: "商品编码",
                              align: "center",
                              field: "goodsNo",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop:
                                            "detailItem." +
                                            scope.rowIndex +
                                            ".goodsNo",
                                          rules:
                                            _vm.rules["detailItem.goodsNo"],
                                        },
                                      },
                                      [
                                        _c("SelectRemote", {
                                          ref:
                                            "goodsNo" +
                                            "_" +
                                            scope.rowIndex +
                                            "_1",
                                          attrs: {
                                            disabled: _vm.disabled,
                                            option: _vm.optionGoods(scope.row),
                                          },
                                          on: {
                                            handleSelectKeydown: function (
                                              $event
                                            ) {
                                              return _vm.handleTableKeydown(
                                                $event,
                                                scope.row,
                                                "goodsNo",
                                                scope.rowIndex,
                                                1
                                              )
                                            },
                                          },
                                          model: {
                                            value: scope.row.goodsNo,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "goodsNo",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.goodsNo",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          },
                          [
                            _c("template", { slot: "header" }, [
                              _c("i", { staticStyle: { color: "#ff4949" } }, [
                                _vm._v("* "),
                              ]),
                              _c("span", [_vm._v("商品编码")]),
                            ]),
                          ],
                          2
                        ),
                        _c("vxe-column", {
                          attrs: {
                            title: "商品名称",
                            align: "center",
                            field: "goodsName",
                          },
                        }),
                        _c(
                          "vxe-column",
                          {
                            attrs: {
                              title: "规格",
                              align: "center",
                              field: "goodsSpec",
                              width: "120",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop:
                                            "detailItem." +
                                            scope.rowIndex +
                                            ".goodsSpec",
                                          rules:
                                            _vm.rules["detailItem.goodsSpec"],
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            ref:
                                              "goodsSpec" +
                                              "_" +
                                              scope.rowIndex +
                                              "_3",
                                            attrs: {
                                              disabled: _vm.disabled,
                                              size: "mini",
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.changeUnits(
                                                  "goodsSpec",
                                                  scope.row,
                                                  scope.row.goodsSpec
                                                )
                                              },
                                            },
                                            nativeOn: {
                                              keydown: function ($event) {
                                                return _vm.handleTableKeydown(
                                                  $event,
                                                  scope.row,
                                                  "goodsSpec",
                                                  scope.rowIndex,
                                                  3
                                                )
                                              },
                                            },
                                            model: {
                                              value: scope.row.goodsSpec,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "goodsSpec",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.goodsSpec",
                                            },
                                          },
                                          _vm._l(
                                            scope.row.units,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.goodsSpec,
                                                attrs: {
                                                  label: item.goodsSpec,
                                                  value: item.goodsSpec,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          },
                          [
                            _c("template", { slot: "header" }, [
                              _c("i", { staticStyle: { color: "#ff4949" } }, [
                                _vm._v("* "),
                              ]),
                              _c("span", [_vm._v("规格")]),
                            ]),
                          ],
                          2
                        ),
                        _c(
                          "vxe-column",
                          {
                            attrs: {
                              title: "单位",
                              align: "center",
                              field: "unitId",
                              width: "110",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop:
                                            "detailItem." +
                                            scope.rowIndex +
                                            ".unitId",
                                          rules: _vm.rules["detailItem.unitId"],
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            ref:
                                              "unitId" +
                                              "_" +
                                              scope.rowIndex +
                                              "_4",
                                            attrs: {
                                              disabled: _vm.disabled,
                                              size: "mini",
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.changeUnits(
                                                  "unitId",
                                                  scope.row,
                                                  scope.row.unitId
                                                )
                                              },
                                            },
                                            nativeOn: {
                                              keydown: function ($event) {
                                                return _vm.handleTableKeydown(
                                                  $event,
                                                  scope.row,
                                                  "unitId",
                                                  scope.rowIndex,
                                                  4
                                                )
                                              },
                                            },
                                            model: {
                                              value: scope.row.unitId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "unitId",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.unitId",
                                            },
                                          },
                                          _vm._l(
                                            scope.row.units,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.unitId,
                                                attrs: {
                                                  label: item.unitName,
                                                  value: item.unitId,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          },
                          [
                            _c("template", { slot: "header" }, [
                              _c("i", { staticStyle: { color: "#ff4949" } }, [
                                _vm._v("* "),
                              ]),
                              _c("span", [_vm._v("单位")]),
                            ]),
                          ],
                          2
                        ),
                        _c("vxe-column", {
                          attrs: {
                            title: "订单需求量",
                            align: "center",
                            field: "needUnitQty",
                            width: "100",
                          },
                        }),
                        _c(
                          "vxe-column",
                          {
                            attrs: {
                              title: "批数",
                              align: "center",
                              width: "120",
                              field: "batchUnitQty",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop:
                                            "detailItem." +
                                            scope.rowIndex +
                                            ".batchUnitQty",
                                          rules:
                                            _vm.rules[
                                              "detailItem.batchUnitQty"
                                            ],
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          ref:
                                            "batchUnitQty" +
                                            "_" +
                                            scope.rowIndex +
                                            "_6",
                                          attrs: {
                                            type: "number",
                                            disabled: _vm.disabled,
                                            size: "mini",
                                            maxlength: "7",
                                            min: 0,
                                          },
                                          on: {
                                            input: function ($event) {
                                              return _vm.changeBatchUnitQty(
                                                scope.row
                                              )
                                            },
                                          },
                                          nativeOn: {
                                            mousewheel: function ($event) {
                                              $event.preventDefault()
                                            },
                                            DOMMouseScroll: function ($event) {
                                              $event.preventDefault()
                                            },
                                            keyup: function ($event) {
                                              return _vm.onkeyupBatchUnitQty(
                                                scope.row
                                              )
                                            },
                                            keydown: [
                                              function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "up",
                                                    38,
                                                    $event.key,
                                                    ["Up", "ArrowUp"]
                                                  )
                                                ) {
                                                  return null
                                                }
                                                $event.preventDefault()
                                                return _vm.handleTableKeydown(
                                                  $event,
                                                  scope.row,
                                                  "batchUnitQty",
                                                  scope.rowIndex,
                                                  6
                                                )
                                              },
                                              function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "down",
                                                    40,
                                                    $event.key,
                                                    ["Down", "ArrowDown"]
                                                  )
                                                ) {
                                                  return null
                                                }
                                                $event.preventDefault()
                                                return _vm.handleTableKeydown(
                                                  $event,
                                                  scope.row,
                                                  "batchUnitQty",
                                                  scope.rowIndex,
                                                  6
                                                )
                                              },
                                              function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "left",
                                                    37,
                                                    $event.key,
                                                    ["Left", "ArrowLeft"]
                                                  )
                                                ) {
                                                  return null
                                                }
                                                if (
                                                  "button" in $event &&
                                                  $event.button !== 0
                                                ) {
                                                  return null
                                                }
                                                $event.preventDefault()
                                                return _vm.handleTableKeydown(
                                                  $event,
                                                  scope.row,
                                                  "batchUnitQty",
                                                  scope.rowIndex,
                                                  6
                                                )
                                              },
                                              function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "right",
                                                    39,
                                                    $event.key,
                                                    ["Right", "ArrowRight"]
                                                  )
                                                ) {
                                                  return null
                                                }
                                                if (
                                                  "button" in $event &&
                                                  $event.button !== 2
                                                ) {
                                                  return null
                                                }
                                                $event.preventDefault()
                                                return _vm.handleTableKeydown(
                                                  $event,
                                                  scope.row,
                                                  "batchUnitQty",
                                                  scope.rowIndex,
                                                  6
                                                )
                                              },
                                              function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                $event.preventDefault()
                                                return _vm.handleTableKeydown(
                                                  $event,
                                                  scope.row,
                                                  "batchUnitQty",
                                                  scope.rowIndex,
                                                  6
                                                )
                                              },
                                            ],
                                          },
                                          model: {
                                            value: scope.row.batchUnitQty,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "batchUnitQty",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "scope.row.batchUnitQty",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          },
                          [
                            _c("template", { slot: "header" }, [
                              _c("i", { staticStyle: { color: "#ff4949" } }, [
                                _vm._v("* "),
                              ]),
                              _c("span", [_vm._v("批数")]),
                            ]),
                          ],
                          2
                        ),
                        _c(
                          "vxe-column",
                          {
                            attrs: {
                              title: "生产计划数",
                              align: "center",
                              width: "130",
                              field: "unitQty",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop:
                                            "detailItem." +
                                            scope.rowIndex +
                                            ".unitQty",
                                          rules:
                                            _vm.rules["detailItem.unitQty"],
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          ref:
                                            "unitQty" +
                                            "_" +
                                            scope.rowIndex +
                                            "_7",
                                          attrs: {
                                            type: "number",
                                            disabled: _vm.disabled,
                                            size: "mini",
                                            maxlength: "7",
                                            min: 0,
                                            sunyunFormat: function (v) {
                                              return _vm.$syInput(1, v)
                                            },
                                          },
                                          on: {
                                            input: function ($event) {
                                              return _vm.limitInputlength(
                                                scope.row.unitQty,
                                                scope.row,
                                                "unitQty",
                                                true
                                              )
                                            },
                                          },
                                          nativeOn: {
                                            mousewheel: function ($event) {
                                              $event.preventDefault()
                                            },
                                            DOMMouseScroll: function ($event) {
                                              $event.preventDefault()
                                            },
                                            keyup: function ($event) {
                                              return _vm.onkeyupUnitQty(
                                                scope.row
                                              )
                                            },
                                            keydown: [
                                              function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "up",
                                                    38,
                                                    $event.key,
                                                    ["Up", "ArrowUp"]
                                                  )
                                                ) {
                                                  return null
                                                }
                                                $event.preventDefault()
                                                return _vm.handleTableKeydown(
                                                  $event,
                                                  scope.row,
                                                  "unitQty",
                                                  scope.rowIndex,
                                                  7
                                                )
                                              },
                                              function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "down",
                                                    40,
                                                    $event.key,
                                                    ["Down", "ArrowDown"]
                                                  )
                                                ) {
                                                  return null
                                                }
                                                $event.preventDefault()
                                                return _vm.handleTableKeydown(
                                                  $event,
                                                  scope.row,
                                                  "unitQty",
                                                  scope.rowIndex,
                                                  7
                                                )
                                              },
                                              function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "left",
                                                    37,
                                                    $event.key,
                                                    ["Left", "ArrowLeft"]
                                                  )
                                                ) {
                                                  return null
                                                }
                                                if (
                                                  "button" in $event &&
                                                  $event.button !== 0
                                                ) {
                                                  return null
                                                }
                                                $event.preventDefault()
                                                return _vm.handleTableKeydown(
                                                  $event,
                                                  scope.row,
                                                  "unitQty",
                                                  scope.rowIndex,
                                                  7
                                                )
                                              },
                                              function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "right",
                                                    39,
                                                    $event.key,
                                                    ["Right", "ArrowRight"]
                                                  )
                                                ) {
                                                  return null
                                                }
                                                if (
                                                  "button" in $event &&
                                                  $event.button !== 2
                                                ) {
                                                  return null
                                                }
                                                $event.preventDefault()
                                                return _vm.handleTableKeydown(
                                                  $event,
                                                  scope.row,
                                                  "unitQty",
                                                  scope.rowIndex,
                                                  7
                                                )
                                              },
                                              function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                $event.preventDefault()
                                                return _vm.handleTableKeydown(
                                                  $event,
                                                  scope.row,
                                                  "unitQty",
                                                  scope.rowIndex,
                                                  7
                                                )
                                              },
                                            ],
                                          },
                                          model: {
                                            value: scope.row.unitQty,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "unitQty",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.unitQty",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          },
                          [
                            _c("template", { slot: "header" }, [
                              _c("i", { staticStyle: { color: "#ff4949" } }, [
                                _vm._v("* "),
                              ]),
                              _c("span", [_vm._v("生产计划数")]),
                            ]),
                          ],
                          2
                        ),
                        _c("vxe-column", {
                          attrs: {
                            title: "半成品仓库存",
                            align: "center",
                            field: "halfProductStoreQty",
                          },
                        }),
                        _c("vxe-column", {
                          attrs: {
                            title: "成品仓库存",
                            align: "center",
                            field: "productStoreQty",
                          },
                        }),
                        _c("vxe-column", {
                          attrs: {
                            title: "备注",
                            align: "center",
                            field: "remark",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-input", {
                                    ref:
                                      "remark" + "_" + scope.rowIndex + "_10",
                                    attrs: {
                                      maxlength: "80",
                                      disabled: _vm.disabled,
                                      size: "mini",
                                    },
                                    nativeOn: {
                                      keydown: function ($event) {
                                        return _vm.handleTableKeydown(
                                          $event,
                                          scope.row,
                                          "remark",
                                          scope.rowIndex,
                                          10
                                        )
                                      },
                                    },
                                    model: {
                                      value: scope.row.remark,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "remark", $$v)
                                      },
                                      expression: "scope.row.remark",
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }