<template>
  <div class="wrap" v-loading="loading">
    <!-- 操作 -->
    <div class="navBtnTop x-c-end">
      <el-button size="mini" @click="lastStep" v-show="activeStep !== 0"
        >上一步</el-button
      >
      <el-button size="mini" @click="nextStep" v-show="activeStep !== 3"
        >下一步</el-button
      >
      <el-button
        type="primary"
        size="mini"
        class="marR10"
        :disabled="disabled"
        v-show="activeStep === 3"
        @click="submitForm(true)"
        >保存</el-button
      >
      <el-dropdown
        split-button
        @click="auditBill('审核')"
        icon="el-icon-circle-check"
        :disabled="showAudit"
        @command="auditBill"
        v-show="activeStep === 3"
        size="mini"
      >
        审核
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="反审核">反审核</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-button
        type="primary"
        icon="el-icon-plus"
        size="mini"
        v-show="showAdd"
        @click="handleAdd"
        >新增</el-button
      >
      <el-button size="mini" @click="getQuit">退出</el-button>
    </div>
    <div class="content">
      <el-form ref="form" :model="batcheditForm" :rules="rules">
        <cardTitleCom cardTitle="当前进度" :billStatus="batcheditForm.billStatus">
          <template slot="cardContent">
            <div class="theSteps">
              <el-steps :active="activeStep" finish-status="success">
                <el-step title="批量修改设置" class="firstStep"></el-step>
                <el-step title="选择配方" class="secondStep"></el-step>
                <el-step title="信息确认与执行" class="thirdStep"></el-step>
              </el-steps>
            </div>
          </template>
        </cardTitleCom>
        <cardTitleCom cardTitle="选择修改类型" v-show="activeStep === 0">
          <template slot="cardContent">
            <div class="modifyType">
              <el-radio-group
                v-model="batcheditForm.optType"
                :disabled="batcheditForm.billId != undefined"
              >
                <el-radio label="0">配方原料替换</el-radio>
                <el-radio label="1">配方新增子件</el-radio>
              </el-radio-group>
            </div>
          </template>
        </cardTitleCom>
        <!-- 配方原料替换 -->
        <cardTitleCom cardTitle="待替换子件" v-show="isBomReplaceCard">
          <template slot="cardContent">
            <div class="tableContent">
              <el-table
                tooltip-effect="dark"
                :data="batcheditForm.insteadGoodsItems"
                border
                :max-height="tableHeightReplace"
                :height="tableHeightReplace"
              >
                <el-table-column label="商品编码" align="center">
                  <template slot="header">
                    <i style="color: #ff4949">* </i>
                    <span>商品编码</span>
                  </template>
                  <el-table-column
                    label="替换前"
                    width="180"
                    align="center"
                    prop="goodsNo"
                  >
                    <template slot-scope="scope">
                      <el-form-item
                        :prop="'insteadGoodsItems.' + scope.$index + '.goodsNo'"
                        :rules="rules[`insteadGoodsItems.goodsNo`]"
                      >
                        <el-input
                          size="mini"
                          v-model="scope.row.goodsNo"
                          :disabled="disabled || activeStep == 3"
                          :ref="'goodsNo'+'_'+scope.$index +'_1'"
                          @keydown.native="handleTableKeydown($event, scope.row,'goodsNo',scope.$index,1)"
                          @blur="onBlurGoodsNo"
                        >
                          <i
                            slot="suffix"
                            class="el-icon-more more"
                            @click="selectGoods('goodsNo')"
                          ></i>
                        </el-input>
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="替换后"
                    width="180"
                    align="center"
                    prop="changeGoodsNo"
                  >
                    <template slot-scope="scope">
                      <el-form-item
                        :prop="'insteadGoodsItems.' + scope.$index + '.changeGoodsNo'"
                        :rules="rules[`insteadGoodsItems.changeGoodsNo`]"
                      >
                        <el-input
                          size="mini"
                          v-model="scope.row.changeGoodsNo"
                          :disabled="disabled || activeStep == 3"
                          :ref="'changeGoodsNo'+'_'+scope.$index +'_2'"
                          @keydown.native="handleTableKeydown($event, scope.row,'changeGoodsNo',scope.$index,2)"
                          @blur="onBlurGoodsNoCh"
                        >
                          <i
                            slot="suffix"
                            class="el-icon-more more"
                            @click="selectGoods('changeGoodsNo')"
                          ></i>
                        </el-input>
                      </el-form-item>
                    </template>
                  </el-table-column>
                </el-table-column>
                <el-table-column label="商品名称" align="center">
                  <el-table-column
                    show-overflow-tooltip
                    label="替换前"
                    align="center"
                    prop="goodsName"
                    width="140"
                  >
                  </el-table-column>
                  <el-table-column
                    show-overflow-tooltip
                    label="替换后"
                    align="center"
                    prop="changeGoodsName"
                    width="140"
                  >
                  </el-table-column>
                </el-table-column>
                <el-table-column label="规格" align="center">
                  <el-table-column
                    show-overflow-tooltip
                    label="替换前"
                    align="center"
                    prop="goodsSpec"
                    width="120"
                  >
                  </el-table-column>
                  <el-table-column
                    show-overflow-tooltip
                    label="替换后"
                    align="center"
                    prop="changeGoodsSpec"
                    width="120"
                  >
                  </el-table-column>
                </el-table-column>
                <el-table-column label="单位" align="center">
                  <template slot="header">
                    <i style="color: #ff4949">* </i>
                    <span>单位</span>
                  </template>
                  <el-table-column
                    show-overflow-tooltip
                    label="替换前"
                    align="center"
                    prop="unitName"
                    width="120"
                  >
                    <template slot-scope="scope">
                      <el-form-item
                        :prop="'insteadGoodsItems.' + scope.$index + '.unitId'"
                        :rules="rules[`insteadGoodsItems.unitId`]"
                      >
                        <el-select
                          size="mini"
                          v-model="scope.row.unitId"
                          :disabled="disabled || activeStep == 3"
                          :ref="'unitId'+'_'+scope.$index +'_7'"
                          @keydown.native="handleTableKeydown($event, scope.row,'unitId',scope.$index,7)"
                        >
                          <el-option
                            v-for="item in scope.row.units"
                            :key="item.unitId"
                            :label="item.unitName"
                            :value="item.unitId"
                          />
                        </el-select>
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <el-table-column
                    show-overflow-tooltip
                    label="替换后"
                    align="center"
                    prop="exchangeUnitId"
                    width="120"
                  >
                    <template slot-scope="scope">
                      <el-form-item
                        :prop="'insteadGoodsItems.' + scope.$index + '.exchangeUnitId'"
                        :rules="rules[`insteadGoodsItems.exchangeUnitId`]"
                      >
                        <el-select
                          size="mini"
                          v-model="scope.row.exchangeUnitId"
                          :disabled="disabled || activeStep == 3"
                          :ref="'exchangeUnitId'+'_'+scope.$index +'_8'"
                          @keydown.native="handleTableKeydown($event, scope.row,'exchangeUnitId',scope.$index,8)"
                        >
                          <el-option
                            v-for="item in scope.row.changeUnits"
                            :key="item.unitId"
                            :label="item.unitName"
                            :value="item.unitId"
                          />
                        </el-select>
                      </el-form-item>
                    </template>
                  </el-table-column>
                </el-table-column>
                <el-table-column label="替换比例(%)" align="center" min-width="120">
                  <template slot-scope="scope">
                    <el-input
                      size="mini"
                      v-model.number="scope.row.exchangeScale"
                      :disabled="disabled || activeStep == 3"
                      maxlength="7"
                      :ref="'exchangeScale'+'_'+scope.$index +'_9'"
                      @keydown.native="handleTableKeydown($event,scope.row, 'exchangeScale',scope.$index,9)"
                    ></el-input>
                  </template>
                </el-table-column>
                <el-table-column label="损耗比例(%)" align="center" min-width="120">
                  <template slot-scope="scope">
                    <el-input
                      size="mini"
                      v-model.number="scope.row.loseRate"
                      :disabled="disabled || activeStep == 3"
                      maxlength="7"
                      :ref="'loseRate'+'_'+scope.$index +'_10'"
                      @keydown.native="handleTableKeydown($event,scope.row, 'loseRate',scope.$index,10)"
                    ></el-input>
                  </template>
                </el-table-column>
                <el-table-column
                  label="备注"
                  align="center"
                  prop="remark"
                  min-width="120"
                >
                  <template slot-scope="scope">
                    <el-input
                      size="mini"
                      v-model.number="scope.row.remark"
                      :disabled="disabled || activeStep == 3"
                      maxlength="80"
                      :ref="'remark'+'_'+scope.$index +'_11'" 
                      @keydown.native="handleTableKeydown($event,scope.row, 'remark',scope.$index,11)"
                    ></el-input>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </template>
        </cardTitleCom>
        <!-- 配方新增子件 -->
        <cardTitleCom cardTitle="配方新增子件" v-show="isBomAddCard">
          <template slot="cardContent">
            <div class="tableContent">
              <el-table
                tooltip-effect="dark"
                :data="batcheditForm.insteadGoodsItems"
                border
                :max-height="tableHeight"
                :height="tableHeight"
                @cell-mouse-enter="cellMouseEnter($event, 'insteadGoodsItems')"
                @cell-mouse-leave="cellMouseLeave('insteadGoodsItems')"
              >
                <el-table-column align="center" :label="tableCellLabel" width="80">
                  <template v-slot="scope">
                    <i
                      v-if="scope.row.hoverRow"
                      @click="row('push', scope.$index)"
                      class="el-icon-circle-plus operatePush"
                    ></i>
                    <i
                      v-if="scope.row.hoverRow"
                      @click="row('del', scope.$index)"
                      class="el-icon-remove operateDel"
                    ></i>
                    <div v-if="!scope.row.hoverRow">{{ scope.$index + 1 }}</div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="商品编码"
                  width="180"
                  align="center"
                  prop="goodsNo"
                >
                  <template slot-scope="scope">
                    <el-input
                      v-model="scope.row.goodsNo"
                      :disabled="disabled || activeStep == 3"
                      :ref="'goodsNo'+'_'+scope.$index +'_1'"
                      @keydown.native="handleTableKeydown($event, scope.row,'goodsNo',scope.$index,1)"
                    >
                      <i
                        slot="suffix"
                        class="el-icon-more more"
                        @click="selectGoods('goodsNoAdd')"
                      ></i>
                    </el-input>
                  </template>
                </el-table-column>
                <el-table-column
                  label="商品名称"
                  align="center"
                  prop="goodsName"
                  width="160"
                />
                <el-table-column
                  label="规格"
                  align="center"
                  prop="goodsSpec"
                  width="130"
                />
                <el-table-column label="单位" align="center" prop="unitId" width="110">
                  <template slot="header">
                    <i style="color: #ff4949">* </i>
                    <span>单位</span>
                  </template>
                  <template slot-scope="scope">
                    <el-form-item
                      :prop="'insteadGoodsItems.' + scope.$index + '.unitId'"
                      :rules="rules[`insteadGoodsItems.unitId`]"
                    >
                      <el-select
                        @change="unitPriceChange($event, scope.row)"
                        v-model="scope.row.unitId"
                        :disabled="disabled || activeStep == 3"
                        filterable
                        :ref="'unitId'+'_'+scope.$index +'_4'"
                        @keydown.native="handleTableKeydown($event, scope.row,'unitId',scope.$index,4)"
                      >
                        <el-option
                          v-for="item in scope.row.units"
                          :key="item.unitId"
                          :label="item.unitName"
                          :value="item.unitId"
                        />
                      </el-select>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="单位用量" align="center" width="130">
                  <template slot="header">
                    <i style="color: #ff4949">* </i>
                    <span>单位用量</span>
                  </template>
                  <template slot-scope="scope">
                    <el-form-item
                      :prop="'insteadGoodsItems.' + scope.$index + '.oneUseQty'"
                      :rules="rules[`insteadGoodsItems.oneUseQty`]"
                    >
                      <el-input
                        v-model="scope.row.oneUseQty"
                        @keyup.native="onkeyupOneUseQty(scope.row)"
                        :disabled="disabled || activeStep == 3"
                        :ref="'oneUseQty'+'_'+scope.$index +'_5'" 
                        @keydown.native="handleTableKeydown($event,scope.row, 'oneUseQty',scope.$index,5)"
                      />
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column
                  label="单位成本"
                  align="center"
                  prop="oneCostMoney"
                  width="130"
                />
                <el-table-column label="损耗率(%)" align="center" width="120">
                  <template slot-scope="scope">
                    <el-input
                      v-model.number="scope.row.loseRate"
                      :disabled="disabled || activeStep == 3"
                      :ref="'loseRate'+'_'+scope.$index +'_7'" 
                      @keydown.native="handleTableKeydown($event,scope.row, 'loseRate',scope.$index,7)"
                    ></el-input>
                  </template>
                </el-table-column>
                <el-table-column label="备注" align="center" prop="remark">
                  <template slot-scope="scope">
                    <el-input
                      v-model.number="scope.row.remark"
                      :disabled="disabled || activeStep == 3"
                      :ref="'remark'+'_'+scope.$index +'_8'" 
                      @keydown.native="handleTableKeydown($event,scope.row, 'remark',scope.$index,8)"
                    ></el-input>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </template>
        </cardTitleCom>
        <!-- 选择需要修改的BOM单 -->
        <cardTitleCom cardTitle="选择需要修改的BOM单" v-show="isBomModifyCard">
          <template slot="cardContent">
            <div class="tableContent">
              <el-table
                tooltip-effect="dark"
                :data="batcheditForm.insteadBillItems"
                border
                :max-height="tableHeight"
                :height="tableHeight"
                @cell-mouse-enter="cellMouseEnter($event, 'insteadBillItems')"
                @cell-mouse-leave="cellMouseLeave('insteadBillItems')"
              >
                <el-table-column align="center" :label="tableCellLabel" width="80">
                  <template v-slot="scope">
                    <i
                      v-if="scope.row.hoverRow"
                      @click="rowTwo('push', scope.$index)"
                      class="el-icon-circle-plus operatePush"
                    ></i>
                    <i
                      v-if="scope.row.hoverRow"
                      @click="rowTwo('del', scope.$index)"
                      class="el-icon-remove operateDel"
                    ></i>
                    <div v-if="!scope.row.hoverRow">{{ scope.$index + 1 }}</div>
                  </template>
                </el-table-column>
                <el-table-column label="配方编号" width="253" align="center" prop="bomNo">
                  <template slot-scope="scope">
                    <el-form-item
                      :prop="'insteadBillItems.' + scope.$index + '.bomNo'"
                      :rules="rules[`insteadBillItems.bomNo`]"
                    >
                      <el-input
                        v-model="scope.row.bomNo"
                        :disabled="disabled || activeStep == 3"
                        @keyup.enter.native="
                          keyupModifyBill('bomNo', $event, scope.$index)
                        "
                      >
                        <i
                          slot="suffix"
                          class="el-icon-more more"
                          @click="ModifyBillDia"
                        ></i>
                      </el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column
                  label="商品编码"
                  width="253"
                  align="center"
                  prop="goodsNo"
                >
                </el-table-column>
                <el-table-column
                  label="商品名称"
                  align="center"
                  width="196"
                  prop="goodsName"
                />
                <el-table-column label="商品类别" align="center" prop="categoryName" />
                <el-table-column
                  width="196"
                  label="品牌名称"
                  align="center"
                  prop="brandName"
                />
                <el-table-column
                  prop="isStop"
                  label="启用"
                  width="120"
                  :show-overflow-tooltip="true"
                  align="center"
                >
                  <template slot-scope="scope">
                    <el-switch
                      :disabled="true"
                      v-model="scope.row.isStop"
                      :active-value="false"
                      :inactive-value="true"
                    ></el-switch>
                  </template>
                </el-table-column>
                <el-table-column label="单据状态" align="center" prop="bomStatusName" />
              </el-table>
            </div>
          </template>
        </cardTitleCom>
      </el-form>
    </div>
    <!-- 弹窗 -->
    <Dialog ref="tablePage" :options.sync="dialogOptions" @handleEvent="handleEvent" />
    <!-- 选择生产配方信息框 -->
    <selectBomService
      ref="bomService"
      :OpenBomService.sync="OpenBomService"
      :queryCode="queryCodeBomService"
      @bomServiceListCom="bomServiceListCom"
    ></selectBomService>
  </div>
</template>

<script>
import cardTitleCom from "@/views/components/cardTitleCom"; //卡片标题
import bottomPage from "@/views/components/bottomPage"; //底部分页
import selectBomService from "@/views/components/selectBomService"; //选择商品
import { listGoods } from "@/api/goods/goods"; //商品列表
import {
  batcheditAddAPI,
  batcheditDetailAPI,
  batcheditUpdateAPI,
  batcheditUpdateStatusAPI,
} from "@/api/produce/bom/batchedit"; //批量修改
import { serviceListAPI } from "@/api/produce/bom/service"; //生产配方接口
import Dialog from "@/components/Dialog";
import tableKeydown from "@/utils/mixin/tableKeydown";
export default {
  name: "batcheditDetail",
  mixins: [tableKeydown],
  components: {
    cardTitleCom,
    bottomPage,
    selectBomService,
    Dialog,
  },
  data() {
    return {
      //弹窗配置
      dialogOptions: {
        title: "选择商品",
        width: 1250,
        show: false,
        type: "TreeAndTable",
        formData: this.$dialog({ key: "goods" }),
      },
      tableCellLabel: "序号",
      replaceName: undefined, //替换的商品选中名称
      showAudit: true, //审核按钮禁用状态
      tableHeight: 615, //表格高度
      tableHeightReplace: 670, //高头表格高度
      isBomReplaceCard: true, //配方原料替换
      isBomAddCard: false, //配方新增子件
      isBomModifyCard: false, //选择需要修改的BOM单
      showAdd: false, //新增按钮
      disabled: false, //保存禁用
      OpenGoods: false, //选择商品信息开关
      OpenBomService: false, //选择生产配方信息开关
      queryCode: undefined, //传送商品参数
      queryCodeBomService: undefined, //传送生产配方参数
      activeStep: 0,
      loadingTable: false, //表格遮罩
      loading: false, //大盒子遮罩层
      //旧的表单
      oldForm: {},
      // 表单参数
      batcheditForm: {
        insteadGoodsItems: [{}],
        insteadBillItems: [{}],
        optType: "0",
        billStatus: "",
      },
      // 表单校验
      rules: {
        "insteadGoodsItems.goodsNo": [
          {
            required: true,
            message: "替换前商品编号不能为空",
            trigger: ["blur", "change"],
          },
        ],
        "insteadGoodsItems.changeGoodsNo": [
          {
            required: true,
            message: "替换后商品编号不能为空",
            trigger: ["blur", "change"],
          },
        ],
        "insteadGoodsItems.exchangeUnitId": [
          {
            required: true,
            message: "单位不能为空",
            trigger: ["blur", "change"],
          },
        ],
        "insteadGoodsItems.unitId": [
          {
            required: true,
            message: "单位不能为空",
            trigger: ["blur", "change"],
          },
        ],
        "insteadGoodsItems.oneUseQty": [
          {
            required: true,
            message: "单位用量不为空",
            trigger: ["blur", "change"],
          },
          {
            pattern: /^\d{0,6}(\.\d{0,4})?$/,
            message: "超出输入字符范围",
          },
        ],
        "insteadGoodsItems.loseRateMaterial": [
          {
            pattern: /^\d{0,3}(\.\d{0,2})?$/,
            message: "超出输入字符范围",
            trigger: ["blur", "change"],
          },
        ],
      },
      
      canKeyTableColumnList:[],//可以键盘导航的表格列详情
      keyTableColumnList1:[
        {key:'goodsNo',columnindex:1,type:'select'},
        {key:'changeGoodsNo',columnindex:2,type:'select'},
        {key:'unitId',columnindex:7,type:'select'},
        {key:'exchangeUnitId',columnindex:8,type:'select'},
        {key:'exchangeScale',columnindex:9},
        {key:'loseRate',columnindex:10},
        {key:'remark',columnindex:11}
      ],
      keyTableColumnList2:[
        {key:'goodsNo',columnindex:1,type:'select'},
        {key:'unitId',columnindex:4,type:'select'},
        {key:'oneUseQty',columnindex:5,type:'select'},
        {key:'loseRate',columnindex:7},
        {key:'remark',columnindex:8}
      ],
      noNeedPushRow:true,
    };
  },
  watch: {
    //侦听是否开启关闭按钮
    "batcheditForm.billStatus": {
      handler(newVal) {
        if (this.batcheditForm.billStatus == "2") {
          //新增按钮
          this.showAdd = true;

          //禁用输入按钮、保存按钮
          this.disabled = true;
          //打开审核按钮
          this.showAudit = false;
        } else if (this.batcheditForm.billStatus == "3") {
          //新增按钮
          this.showAdd = false;

          //禁用审核按钮
          this.showAudit = true;
          //禁止全部输入按钮
          this.disabled = true;
        } else if (this.batcheditForm.billStatus == "0") {
          //新增按钮
          this.showAdd = false;

          //禁用审核按钮
          this.showAudit = false;

          //恢复全部输入
          this.disabled = false;
        } else if (this.batcheditForm.billStatus == "4") {
          //新增按钮
          this.showAdd = false;

          //禁用审核按钮
          this.showAudit = false;

          //恢复全部输入
          this.disabled = false;
        } else if (this.batcheditForm.billStatus == "") {
          //新增按钮
          this.showAdd = false;

          //禁用审核按钮
          this.showAudit = true;
          //恢复全部输入
          this.disabled = false;
        }
      },
      immediate: true,
    },
    // 修改类型
    "batcheditForm.optType": {
      handler(val) {
        if (this.batcheditForm.billStatus == "") {
          this.batcheditForm.insteadGoodsItems = [{}];
        }
        //判断是什么类型
        if (val == "0") {
          //待替换是否必传
          this.rules[`insteadGoodsItems.changeGoodsNo`][0].required = true;
          //单位
          this.rules["insteadGoodsItems.exchangeUnitId"][0].required = true;
          this.rules["insteadGoodsItems.unitId"][0].required = false;
          //单位用量
          this.rules["insteadGoodsItems.oneUseQty"][0].required = false;
          // console.log('===', this.rules['insteadGoodsItems.unitId'][0].required)
        } else {
          this.rules[`insteadGoodsItems.changeGoodsNo`][0].required = false;
          //单位
          this.rules["insteadGoodsItems.exchangeUnitId"][0].required = false;
          this.rules["insteadGoodsItems.unitId"][0].required = true;
          //单位用量
          this.rules["insteadGoodsItems.oneUseQty"][0].required = true;
        }
        // 配方原料替换
        if (
          (this.activeStep == 0 || this.activeStep == 2 || this.activeStep == 3) &&
          this.batcheditForm.optType == "0"
        ) {
          this.isBomReplaceCard = true;
        } else {
          this.isBomReplaceCard = false;
        }
        //配方新增子件
        if (
          (this.activeStep == 0 || this.activeStep == 2 || this.activeStep == 3) &&
          this.batcheditForm.optType == "1"
        ) {
          this.isBomAddCard = true;
        } else {
          this.isBomAddCard = false;
        }
        //选择需要修改的BOM单
        if (this.activeStep == 1 || this.activeStep == 2 || this.activeStep == 3) {
          this.isBomModifyCard = true;
        } else {
          this.isBomModifyCard = false;
        }
      },
      deep: true,
      immediate: true,
    },
    // 步骤
    activeStep(val) {
      // 配方原料替换
      if (
        (this.activeStep == 0 || this.activeStep == 2 || this.activeStep == 3) &&
        this.batcheditForm.optType == "0"
      ) {
        this.isBomReplaceCard = true;
      } else {
        this.isBomReplaceCard = false;
      }
      //配方新增子件
      if (
        (this.activeStep == 0 || this.activeStep == 2 || this.activeStep == 3) &&
        this.batcheditForm.optType == "1"
      ) {
        this.isBomAddCard = true;
      } else {
        this.isBomAddCard = false;
      }
      //选择需要修改的BOM单
      if (this.activeStep == 1 || this.activeStep == 2 || this.activeStep == 3) {
        this.isBomModifyCard = true;
      } else {
        this.isBomModifyCard = false;
      }
      //步骤3
      if (this.activeStep == 2 || this.activeStep == 3) {
        // 表格高度
        this.tableHeight = 330;
        // 高头表格高度
        this.tableHeightReplace = 385;
      } else {
        // 表格高度
        this.tableHeight = 615;
        // 高头表格高度
        this.tableHeightReplace = 670;
      }
    },
    isBomReplaceCard(val){
      if(val){
        this.canKeyTableColumnList = this.keyTableColumnList1;
        this.noNeedPushRow = true;
      }
    },
    isBomAddCard(val){
      if(val){
        this.canKeyTableColumnList = this.keyTableColumnList2;
        this.noNeedPushRow = false;
      }
    },
    'batcheditForm.insteadGoodsItems'(val){
      this.hasTableKey = val;
    }
  },
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      vm.loading = true;
      // 访问组件实例 vm 中的数据和方法
      if (vm.$route.query.type === "Update") {
        if (vm.$route.query.billId) {
          //获取单据详情
          const { data } = await batcheditDetailAPI(vm.$route.query.billId);
          //单据赋值
          vm.batcheditForm = data;
          //原始单据赋值
          vm.oldForm = JSON.parse(JSON.stringify(vm.batcheditForm));
        }
      } else {
        vm.reset();
        //判断是否有以前新增时保存的数据
        if (JSON.stringify(vm.oldForm) != "{}") {
          vm.batcheditForm = vm.oldForm;
        }
        vm.oldForm = JSON.parse(JSON.stringify(vm.batcheditForm));
      }
      vm.loading = false;
    });
  },

  async beforeRouteLeave(to, from, next) {
    // 在用户离开二级路由前弹出询问框
    if (this.batcheditForm.billId) {
      this.oldForm = {};
    } else {
      this.oldForm = JSON.parse(JSON.stringify(this.batcheditForm));
    }
    next();
  },
  methods: {
    //弹窗确定事件
    async handleEvent(type, row) {
      switch (type) {
        case "dialogChange":
          if (this.replaceName != "goodsNoAdd") {
            this.getGoodsItem(this.dialogOptions.formData.table.check[0]);
          } else {
            this.getGoodS(this.dialogOptions.formData.table.check);
          }
          break;
        default:
          break;
      }
    },
    //选择商品方法
    selectGoods(value) {
      //点击的那个商品名称
      this.replaceName = value;
      this.dialogOptions.formData = this.$dialog({ key: "goods" });
      this.dialogOptions.formData.table.pagination.goodsTypes = [2, 3];
      this.dialogOptions.show = true;
    },
    //选中的商品
    getGoodS(value) {
      this.loadingTable = true;
      //去重
      value = value.filter((item) => {
        return this.batcheditForm.insteadGoodsItems.every(
          (v) => v.goodsId != item.goodsId
        );
      });
      if (value.length == 0) {
        this.loadingTable = false;
        return;
      }
      value.forEach((v) => {
        this.$set(v, "oneCostPrice", v.purPrice);
        v.remark = undefined;
      });
      this.batcheditForm.insteadGoodsItems.push(...value);
      this.batcheditForm.insteadGoodsItems = this.batcheditForm.insteadGoodsItems.filter(
        (item) =>
          (!(JSON.stringify(item) === "{}") && !(item.goodsId === undefined)) ||
          !(item.goodsId === undefined)
      );
      this.loadingTable = false;
    },
    //单选中的商品
    getGoodsItem(value) {
      this.loadingTable = true;
      if (value && value.goodsId) {
        //原始赋值
        let obj = {};
        if (this.replaceName == "goodsNo") {
          let newObj = {
            ...this.batcheditForm.insteadGoodsItems[0],
            unitName: undefined,
            goodsNo: undefined,
            goodsName: undefined,
            goodsSpec: undefined,
            goodsId: undefined,
            unitId: undefined,
            units: [],
          };
          newObj.unitName = value.unitName;
          newObj.unitId = value.unitId;
          newObj.units = value.units;
          newObj.units.forEach((v) => {
            if (v.unitId == newObj.unitId) {
              this.$set(newObj, "oneCostPrice", v.unitPrice);
            }
          });
          newObj.goodsNo = value.goodsNo;
          newObj.goodsName = value.goodsName;
          newObj.goodsSpec = value.goodsSpec;
          newObj.goodsId = value.goodsId;
          obj = newObj;
        } else if (this.replaceName == "changeGoodsNo") {
          let newObj = {
            ...this.batcheditForm.insteadGoodsItems[0],
            changeGoodsNo: undefined,
            changeGoodsName: undefined,
            changeGoodsSpec: undefined,
            changeGoodsId: undefined,
            exchangeUnitId: undefined,
            changeUnits: [],
            oneCostPrice: undefined,
            oneUseQty: undefined,
            loseRate: 0,
          };
          newObj.changeGoodsNo = value.goodsNo;
          newObj.changeGoodsName = value.goodsName;
          newObj.changeGoodsSpec = value.goodsSpec;
          newObj.changeGoodsId = value.goodsId;
          newObj.exchangeUnitId = value.unitId;
          newObj.changeUnits = value.units;
          newObj.oneCostPrice = value.purPrice;
          obj = newObj;
        }
        this.batcheditForm.insteadGoodsItems = [obj];
      }
      this.loadingTable = false;
    },
    //表格行hover时,显示操作加减号
    cellMouseEnter(row, arr) {
      if (!this.disabled && this.activeStep !== 3) {
        let eleIndex = this.batcheditForm[arr].indexOf(row);
        for (let index = 0; index < this.batcheditForm[arr].length; index++) {
          const element = this.batcheditForm[arr][index];
          if (eleIndex == index) {
            element["hoverRow"] = true;
          } else {
            element["hoverRow"] = false;
          }
        }
        this.tableCellLabel = "操作";
      }
    },
    //表格行离开hover时,不显示操作加减号,显示序号
    cellMouseLeave(arr) {
      // 移除hover的事件
      for (let index = 0; index < this.batcheditForm[arr].length; index++) {
        const element = this.batcheditForm[arr][index];
        element["hoverRow"] = false;
      }
      this.tableCellLabel = "序号";
    },
    //新增按钮
    handleAdd() {
      this.reset();
    },
    //退出
    getQuit() {
      this.$store.state.tagsView.visitedViews.splice(
        this.$store.state.tagsView.visitedViews.findIndex(
          (item) => item.path === this.$route.path
        ),
        1
      );
      this.$router.push("/produce/bom/batchedit");
    },
    //老的商品编码失焦
    onBlurGoodsNo() {
      if (this.batcheditForm.insteadGoodsItems[0].goodsName == undefined) {
        this.batcheditForm.insteadGoodsItems[0].goodsNo = undefined;
      }
    },
    //新的商品编码失焦
    onBlurGoodsNoCh() {
      if (this.batcheditForm.insteadGoodsItems[0].changeGoodsName == undefined) {
        this.batcheditForm.insteadGoodsItems[0].changeGoodsNo = undefined;
      }
    },
    /** 提交按钮 */
    async submitForm(isBool) {
      if (!this.batcheditForm.insteadBillItems.some((v) => v.bomId)) {
        this.$message.error("需要修改的BOM单不能为空");
        return;
      }
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          if (this.batcheditForm.billId) {
            const { data } = await batcheditUpdateAPI(this.batcheditForm);
            //重新赋值给表单，可以把id赋值
            if (isBool) {
              this.batcheditForm = JSON.parse(JSON.stringify(data));
              this.$modal.msgSuccess("修改单据成功");
            } else {
              this.$modal.msgSuccess("保存并新增单据成功");
            }
          } else {
            const { data } = await batcheditAddAPI(this.batcheditForm);
            //重新赋值给表单，可以把id赋值
            if (isBool) {
              this.batcheditForm = JSON.parse(JSON.stringify(data));
              console.log("0000", data);
              console.log("1111111111", this.batcheditForm);
              this.$modal.msgSuccess("新增单据成功");
            } else {
              this.$modal.msgSuccess("保存并修改单据成功");
            }
          }
        }
      });
    },
    //多级单位换算
    unitPriceChange(value, row) {
      row.units.forEach((v) => {
        if (v.unitId == value) {
          this.$set(row, "oneCostPrice", v.unitPrice);
          if (row.oneUseQty && row.oneCostPrice) {
            const oneCostMoney = row.oneUseQty * row.oneCostPrice;
            this.$set(row, "oneCostMoney", oneCostMoney);
          } else {
            this.$set(row, "oneCostMoney", 0);
          }
        }
      });
    },
    //用量键盘抬起
    onkeyupOneUseQty(row) {
      if (row.oneUseQty && row.oneCostPrice) {
        const oneCostMoney = row.oneUseQty * row.oneCostPrice;
        this.$set(row, "oneCostMoney", oneCostMoney);
      } else {
        this.$set(row, "oneCostMoney", 0);
      }
    },
    //生产配方
    bomServiceListCom(value) {
      if (value.length == 0) return;
      value.forEach((v) => {
        this.$set(v, "bomBillId", v.bomId);
      });
      this.batcheditForm.insteadBillItems.push(...value);
      this.batcheditForm.insteadBillItems = this.batcheditForm.insteadBillItems.filter(
        (item) =>
          (!(JSON.stringify(item) === "{}") && !(item.bomId === undefined)) ||
          !(item.bomId === undefined)
      );
    },
    //打开生产配方弹窗
    ModifyBillDia() {
      this.$refs.bomService.queryParams.bomStatuses = ["2"];
      if (this.batcheditForm.optType == "0") {
        this.$refs.bomService.queryParams.bomGoodsId = this.batcheditForm.insteadGoodsItems[0].goodsId;
        this.$refs.bomService.queryParams.unitId = this.batcheditForm.insteadGoodsItems[0].unitId;
      }
      this.OpenBomService = true;
    },
    //生产配方弹窗回车
    keyupModifyBill(name, event, index) {
      //判断是否输入编码或条码
      if (this.batcheditForm.insteadBillItems[`${index}`][`${name}`]) {
        const bomGoodsId = "";
        const unitId = "";
        if (this.batcheditForm.optType == "0") {
          bomGoodsId = this.batcheditForm.insteadGoodsItems[0].goodsId;
          unitId = this.batcheditForm.insteadGoodsItems[0].unitId;
        }
        serviceListAPI({
          [name]: this.batcheditForm.insteadBillItems[`${index}`][`${name}`],
          bomStatuses: ["2"],
          bomGoodsId,
          unitId,
        }).then((response) => {
          // 判断是否精准匹配 匹配直接赋值 不匹配弹出商品框
          if (response.total === 1) {
            event.target.blur();
            //原始赋值

            //使用vue的$set方法更新数组数据,使视图可编辑
            this.$set(this.batcheditForm.insteadBillItems, index, response.rows[0]);
          } else {
            this.queryCodeBomService = this.batcheditForm.insteadBillItems[`${index}`][
              `${name}`
            ];
            this.$refs.bomService.queryParams.bomStatuses = ["2"];
            if (this.batcheditForm.optType == "0") {
              this.$refs.bomService.queryParams.bomGoodsId = this.batcheditForm.insteadGoodsItems[0].goodsId;
              this.$refs.bomService.queryParams.unitId = this.batcheditForm.insteadGoodsItems[0].unitId;
            }
            this.OpenBomService = true;
          }
        });
      } else {
        //没输入直接弹出商品信息框
        event.target.blur();
        this.$refs.bomService.queryParams.bomStatuses = ["2"];
        if (this.batcheditForm.optType == "0") {
          this.$refs.bomService.queryParams.bomGoodsId = this.batcheditForm.insteadGoodsItems[0].goodsId;
          this.$refs.bomService.queryParams.unitId = this.batcheditForm.insteadGoodsItems[0].unitId;
        }
        this.OpenBomService = true;
      }
    },
    //审核/删除/终止事件
    async auditBill(name) {
      try {
        await this.$confirm(`确定${name}该订单, 是否继续?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "success",
        });

        if (name === "审核") {
          if (this.batcheditForm.billStatus != "0") {
            this.$message.error("此单据不是未审核的状态哦~");
            return;
          }
          const obj = {
            billIds: [this.batcheditForm.billId],
            billStatus: "2",
          };
          //发送审核api
          const { data } = await batcheditUpdateStatusAPI(obj);
          this.batcheditForm = data;
          // 弹出提示
          this.$message.success("审核成功");
        } else if (name === "反审核") {
          if (this.batcheditForm.billStatus != "2") {
            this.$message.error("此单据不是已审核的状态哦~");
            return;
          }
          const obj = {
            billIds: [this.batcheditForm.billId],
            billStatus: "0",
          };
          //发送反审核api
          const { data } = await batcheditUpdateStatusAPI(obj);
          this.batcheditForm = data;
          // 弹出提示
          this.$message.success("反审核成功");
        }
      } catch {}
    },
    // 上一步骤
    lastStep() {
      if (this.activeStep > 0) {
        if (this.activeStep == 3) {
          this.activeStep--;
          this.activeStep--;
        } else {
          this.activeStep--;
        }
      }
    },
    // 下一步骤
    nextStep() {
      if (
        this.activeStep == 0 &&
        this.batcheditForm.optType == "0" &&
        !this.batcheditForm.insteadGoodsItems.every((v) => v.goodsId && v.changeGoodsId)
      ) {
        this.$message.error("待替换子件信息未填写完整");
        return;
      }
      if (this.activeStep < 3) {
        if (this.activeStep == 1) {
          this.activeStep++;
          this.activeStep++;
        } else {
          this.activeStep++;
        }
      }
    },
    reset() {
      //新增按钮
      this.showAdd = false;
      this.canKeyTableColumnList = this.keyTableColumnList1;
      //保存禁用
      this.disabled = false;
      this.isBomReplaceCard = true; //配方原料替换
      this.isBomAddCard = false; //配方新增子件
      this.isBomModifyCard = false; //选择需要修改的BOM单
      this.activeStep = 0; //第一步
      // 表单参数
      this.batcheditForm = {
        insteadGoodsItems: [{}],
        insteadBillItems: [{}],
        optType: "0",
        billStatus: "",
      };
      this.resetForm("form");
    },
    //表格单元格回车事件
    keyup(name, event, index) {
      //判断是否输入编码或条码
      if (this.batcheditForm.insteadGoodsItems[`${index}`][`${name}`]) {
        listGoods({
          goodsNo: this.batcheditForm.insteadGoodsItems[`${index}`][`${name}`],
          goodsTypes: [2, 3],
        }).then((response) => {
          // 判断是否精准匹配 匹配直接赋值 不匹配弹出商品框
          if (response.total === 1) {
            event.target.blur();
            //去重
            let isRepeat = this.batcheditForm.insteadGoodsItems.every(
              (item) => item.goodsId != response.rows[0].goodsId
            );
            if (!isRepeat) {
              this.$message.error("当前表格已有此商品!");
              return;
            }
            //原始赋值
            let obj = {};
            if (name == "goodsNo") {
              let newObj = {
                ...this.batcheditForm.insteadGoodsItems[index],
                unitName: undefined,
                goodsNo: undefined,
                goodsName: undefined,
                goodsSpec: undefined,
                goodsId: undefined,
                unitId: undefined,
                units: [],
              };
              newObj.unitId = response.rows[0].unitId;
              newObj.units = response.rows[0].units;
              newObj.units.forEach((v) => {
                if (v.unitId == newObj.unitId) {
                  this.$set(newObj, "oneCostPrice", v.unitPrice);
                }
              });
              newObj.unitName = response.rows[0].unitName;
              newObj.goodsNo = response.rows[0].goodsNo;
              newObj.goodsName = response.rows[0].goodsName;
              newObj.goodsSpec = response.rows[0].goodsSpec;
              newObj.goodsId = response.rows[0].goodsId;
              obj = newObj;
            } else if (name == "changeGoodsNo") {
              let newObj = {
                ...this.batcheditForm.insteadGoodsItems[index],
                changeGoodsNo: undefined,
                changeGoodsName: undefined,
                changeGoodsSpec: undefined,
                changeGoodsId: undefined,
                exchangeUnitId: undefined,
                changeUnits: [],
                oneCostPrice: undefined,
                oneUseQty: undefined,
                loseRate: 0,
              };
              newObj.changeGoodsNo = response.rows[0].goodsNo;
              newObj.changeGoodsName = response.rows[0].goodsName;
              newObj.changeGoodsSpec = response.rows[0].goodsSpec;
              newObj.changeGoodsId = response.rows[0].goodsId;
              newObj.exchangeUnitId = response.rows[0].unitId;
              newObj.changeUnits = response.rows[0].units;
              newObj.oneCostPrice = response.rows[0].purPrice;
              obj = newObj;
            }
            //使用vue的$set方法更新数组数据,使视图可编辑
            this.$set(this.batcheditForm.insteadGoodsItems, index, obj);
          } else {
            this.queryCode = this.batcheditForm.insteadGoodsItems[`${index}`][`${name}`];
            this.$refs.selectGoods.queryParams.goodsTypes = [2, 3];
            this.OpenGoods = true;
          }
        });
      } else {
        //没输入直接弹出商品信息框
        event.target.blur();
        this.$refs.selectGoods.queryParams.goodsTypes = [2, 3];
        this.OpenGoods = true;
      }
    },
    //表格当中增加/减少一行方法
    rowTwo(name, index) {
      if (name === "push") {
        this.batcheditForm.insteadBillItems.splice(index + 1, 0, {});
      } else {
        this.batcheditForm.insteadBillItems.splice(index, 1);
        if (this.batcheditForm.insteadBillItems?.length <= 0) {
          this.batcheditForm.insteadBillItems = [{}];
        }
      }
    },
    //配方新增组件表格当中增加/减少一行方法
    row(name, index) {
      if (name === "push") {
        this.batcheditForm.insteadGoodsItems.splice(index + 1, 0, {});
      } else {
        this.batcheditForm.insteadGoodsItems.splice(index, 1);
        if (this.batcheditForm.insteadGoodsItems?.length <= 0) {
          this.batcheditForm.insteadGoodsItems = [{}];
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
//外面盒子
.wrap {
  background-color: #eaeaea;
  padding: 3px 10px;
  //操作按钮
  .navBtnTop {
    padding: 6px 10px 6px 0;
  }
  //主体
  .content {
    //进度条
    .theSteps {
      margin: 22px;
    }
    //修改类型
    .modifyType {
      margin: 26px 0 29px 20px;
    }
    .tableContent {
      margin: 10px 10px 0 10px;
      ::v-deep .el-form-item {
        padding: 0;
        margin: 0;
      }
      ::v-deep .el-form-item__error {
        position: static;
      }
    }
  }
}
/* 单选框选中后的字体颜色 */
::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: #303133 !important;
}
/* 进行中状态：圈线 */
::v-deep .el-step__head.is-process {
  color: #338df7;
  border-color: #338df7;
}
/* 进行中状态：圈内 */
::v-deep .el-step__head.is-process > .el-step__icon {
  background: #338df7;
  color: #fff;
}
/* 进行中状态：title（文字） */
::v-deep .el-step__title.is-process {
  font-size: 12px;
  font-weight: normal;
}

/* 完成状态：圈线 */
::v-deep .el-step__head.is-success {
  color: #338df7;
  border-color: #338df7;
}
/* 完成状态：title（文字） */
::v-deep .el-step__title.is-success {
  color: #303133;
  font-size: 12px;
  font-weight: normal;
}
// 未完成:title(文字)
::v-deep .el-step__title.is-wait {
  color: #303133;
  font-size: 12px;
  font-weight: normal;
}
/* 完成状态：line
 * 描述：第一步完成，第二步进行时，之间的进度条有颜色
 */
::v-deep .el-step__head.is-success > .el-step__line > .el-step__line-inner {
  width: 100% !important;
  border-width: 1px !important;
}
::v-deep .el-steps .el-steps--horizontal {
  padding: 0 20px;
}
//进度条
.firstStep {
  ::v-deep .el-step__head {
    margin-left: 20px;
  }
}
.secondStep {
  ::v-deep .el-step__head {
    margin-left: 10px;
  }
}
::v-deep .el-step.secondStep.is-horizontal {
  margin-right: -20px !important;
}
.thirdStep {
  ::v-deep .el-step__head {
    padding-left: 30px;
  }
}
</style>
